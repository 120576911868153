import { Component, OnInit } from '@angular/core';
import { Globals } from '.././globals';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { PracticeTestService } from '../services/practice-test.service';
declare var $, swal: any;

@Component({
  selector: 'app-practice-test',
  templateUrl: './practice-test.component.html',
  styleUrls: ['./practice-test.component.css']
})
export class PracticeTestComponent implements OnInit {

  practiceTestListEntity;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  SubmitDisabled;
  TotalAnswered;
  TotalUnanswered;
  practiceTestEntity;
  categoryList;
  addPracticeTestEntity;
  certificatename;
  categoryname;
  finalSubmitEntity;
  countEntity;
  UserPracticeTestId;
  UserPracticeTestMappingId;
  PracticeTotalItems;
  CategoryAssessmentTime;

  constructor(private router: Router, private route: ActivatedRoute, private PracticeTestService: PracticeTestService, public globals: Globals) { }

  ngOnInit() {

    $('#practicecategorymodal').modal('show');

    // alert(this.CategoryAssessmentTime);

    // setTimeout(function () {     

    //   $('.timer').startTimer({

    //     onComplete: function (element) {

    //       swal({
    //         type : 'warning',
    //         title : 'Time Out ! ',
    //         text: "The given time for this assessment is over, Click on view result button to know your assessment score. Thank You ",
    //         showConfirmButton: false,
    //         confirmButtonText: "View Result",
    //         showCancelButton: true,
    //         cancelButtonText: "Cancel",
    //         allowOutsideClick: false
    //       }).then(function(){
    //           document.getElementById('assessment_submit').click();
    //       });
    //     }
    //   });
    // }, 500);

    this.practiceTestListEntity = {};
    this.practiceTestEntity = {};
    this.categoryList = {};
    this.addPracticeTestEntity = {};
    this.finalSubmitEntity = {};
    this.countEntity = [];
    var certificateid = this.route.snapshot.paramMap.get('certificateid');
    var usercertificateid = this.route.snapshot.paramMap.get('usercertificateid');
    var userpracticetestid = this.route.snapshot.paramMap.get('userpracticetestid');
    this.PracticeTestService.getByCertificateId(certificateid, userpracticetestid)
      .then((data) => {
        // debugger

        this.categoryList = data;
        console.log(this.categoryList);
        this.certificatename = data[0].CertificateName;
      },
        (error) => {
          this.globals.isLoading = false;
          swal({
            type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
            title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
            text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
            showConfirmButton: false,
            timer: 4000
          })
        });
  }

  addPracticeTest(i) {
    debugger
    if (this.UserPracticeTestId > 0){
      this.addPracticeTestEntity.UserPracticeTestId = this.UserPracticeTestId;
      this.addPracticeTestEntity.Flag = 1;
  }
    else{
      this.addPracticeTestEntity.UserPracticeTestId = 0;
      this.addPracticeTestEntity.Flag = this.route.snapshot.paramMap.get('flag');
    }
     
    if (this.UserPracticeTestMappingId > 0){
      this.addPracticeTestEntity.UserPracticeTestMappingId = this.UserPracticeTestMappingId;
    }
    else{
      this.addPracticeTestEntity.UserPracticeTestMappingId = 0;
    }
      

    this.categoryname = this.categoryList[i].CategoryName;
    this.PracticeTotalItems = this.categoryList[i].PracticeTotalItems;
    this.CategoryAssessmentTime = parseInt(this.categoryList[i].CategoryAssessmentTime);
    
    // alert(this.CategoryAssessmentTime);

    this.globals.isLoading = true;
    var usercertificateid = this.route.snapshot.paramMap.get('usercertificateid');
    this.addPracticeTestEntity.UserCertificateId = usercertificateid;
    
    this.addPracticeTestEntity.UserId = this.globals.authData.UserId;
    this.addPracticeTestEntity.CertificateId = this.categoryList[i].CertificateId;
    this.addPracticeTestEntity.CategoryId = this.categoryList[i].CategoryId;
    this.addPracticeTestEntity.CertificatePracticeTestMappingId = this.categoryList[i].CertificatePracticeTestMappingId;
    this.addPracticeTestEntity.NoneScoreItems = this.categoryList[i].NoneScoreItems;
    this.addPracticeTestEntity.PracticeTotalItems = this.categoryList[i].PracticeTotalItems;
    this.addPracticeTestEntity.CategoryAssessmentTime = parseInt(this.categoryList[i].CategoryAssessmentTime);

    // var b = document.querySelector(".timer");
    // b.setAttribute('data-minutes-left', this.addPracticeTestEntity.CategoryAssessmentTime);
    // $('.timer').startTimer();

    // console.log(this.addPracticeTestEntity);
    // alert(this.categoryList[i].CategoryAssessmentTime);

    this.PracticeTestService.addPracticeTest(this.addPracticeTestEntity)
      .then((data) => {
        // debugger        
        if (data) {
          this.UserPracticeTestId = data['UserPracticeTestId'];
          this.UserPracticeTestMappingId = data['UserPracticeTestMappingId'];
          this.PracticeTestService.getById(this.UserPracticeTestMappingId)
            .then((data1) => {
               debugger        
              this.globals.isLoading = false;
              $('#carousel').flexslider('destroy');
              $('#slider').flexslider('destroy');
              $('#practicecategorymodal').modal('hide');
              $('.flexslider').removeData('flexslider');
              this.practiceTestListEntity = {};
              this.practiceTestListEntity = data1;
              console.log(this.practiceTestListEntity);
              this.finalSubmitEntity.UserPracticeTestMappingId = this.practiceTestListEntity[0].UserPracticeTestMappingId;
              setTimeout(function () {
                $('#carousel').flexslider({
                  animation: "slide",
                  controlNav: false,
                  animationLoop: false,
                  slideshow: false,
                  itemWidth: 60,
                  itemMargin: 0,
                  asNavFor: '#slider'
                });
                $('#slider').flexslider({
                  animation: "slide",
                  controlNav: false,
                  animationLoop: false,
                  slideshow: false,
                  sync: "#carousel"
                });
              }, 500);
              setTimeout(function () {
                // alert($("#slider").find(".flex-direction-nav").length);
                // alert($("#carousel").find(".flex-direction-nav").length);
                $("#carousel .flex-direction-nav").removeAttr("style");
                $("#slider .flex-direction-nav").removeAttr("style");
                $("#carousel .flex-direction-nav").last().css("display", "block");
                $("#slider .flex-direction-nav").last().css("display", "block");
              }, 1000);
            },
              (error) => {
                this.globals.isLoading = false;
                swal({
                  type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
                  title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
                  text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
                  showConfirmButton: false,
                  timer: 4000
                })
              });
        }
      },
        (error) => {
          this.globals.isLoading = false;
          swal({
            type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
            title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
            text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
            showConfirmButton: false,
            timer: 4000
          })
        });
  }

  k = 1;
  questionitemid = '';
  addPracticeAnswer(ItemOptionId, CorrectOptionId, PracticeTestAnswerId, i, itemid) {
    if (this.questionitemid == '') {
      this.questionitemid = itemid;
    }
    if (this.questionitemid != '' && this.questionitemid != itemid) {
      this.questionitemid = itemid;
      this.k++;
    }
    if (this.practiceTestEntity.MarkAsReview == true) {
      this.practiceTestEntity.MarkAsReview = 1;
    }
    else
      this.practiceTestEntity.MarkAsReview = 0;

    $("#questionblock" + (i + 1)).addClass('complete');
    this.practiceTestEntity.PracticeTestAnswerId = PracticeTestAnswerId;
    this.practiceTestEntity.UserItemOptionId = ItemOptionId;
    this.practiceTestEntity.CorrectOptionId = CorrectOptionId;
    this.practiceTestEntity.UserId = this.globals.authData.UserId;
    this.practiceTestEntity.MarkAsReview = this.practiceTestListEntity[i].MarkAsReview;
     
    this.PracticeTestService.AddPracticeAnswers(this.practiceTestEntity)
      .then((data1) => {

      },
        (error) => {
        });
    this.countEntity.push(this.practiceTestEntity);

    //$('#totalAnswered').text(this.k);
    //this.k++;

  }
  markasreview(CorrectOptionId, PracticeTestAnswerId, i,ItemId) {
    debugger
    //alert(CorrectOptionId);
    var UserItemOptionId = '';
    //console.log(CorrectOptionId);
    if(this.practiceTestListEntity[i].UserItemOptionId == null){
      UserItemOptionId = null;
    }
    else{
      UserItemOptionId = this.practiceTestListEntity[i].UserItemOptionId;
    }
    //$("#questionblock" + (i + 1)).addClass('fa fa-star');
   // alert(CorrectOptionId+" "+AssessmentAnswerId+" "+i+" "+ ItemId);
   //this.addAssessmentAnswer(UserItemOptionId,CorrectOptionId,AssessmentAnswerId,i,ItemId);
   if (this.practiceTestListEntity[i].MarkAsReview==true) {
    this.practiceTestListEntity[i].MarkAsReview = 1;
  }
  else{
    this.practiceTestListEntity[i].MarkAsReview = 0;
  }
  
  this.practiceTestEntity.PracticeTestAnswerId = PracticeTestAnswerId;
  this.practiceTestEntity.UserItemOptionId = UserItemOptionId;
  this.practiceTestEntity.CorrectOptionId = CorrectOptionId;
  this.practiceTestEntity.UserId = this.globals.authData.UserId;
  this.practiceTestEntity.MarkAsReview = this.practiceTestListEntity[i].MarkAsReview;

  this.PracticeTestService.AddPracticeAnswers(this.practiceTestEntity)
    .then((data1) => {

    },
      (error) => {
        //this.answerCount ++;
      });
  }
  finalSubmit() {
    //TotalCategories  
    this.finalSubmitEntity.TotalCategories = this.categoryList.length;
    this.finalSubmitEntity.CertificateId = this.route.snapshot.paramMap.get('certificateid');;

    this.finalSubmitEntity.UserPracticeTestId = this.practiceTestListEntity[0].UserPracticeTestId;
    this.finalSubmitEntity.UserId = this.globals.authData.UserId;
    this.finalSubmitEntity.UserCertificateId = this.route.snapshot.paramMap.get('usercertificateid');

    var certificateid = this.route.snapshot.paramMap.get('certificateid');
    var count = 0;
    for (let i = 0; i < this.practiceTestListEntity.length; i++) {
      if(this.practiceTestListEntity[i]['UserItemOptionId']==null || this.practiceTestListEntity[i]['UserItemOptionId']=='' || this.practiceTestListEntity[i]['UserItemOptionId']==undefined){
        count++;
      }
    }
    if(count==0){
    this.globals.isLoading = true;
    this.PracticeTestService.AddFinalSubmit(this.finalSubmitEntity)
      .then((data1) => {
        this.PracticeTestService.getByCertificateId(certificateid, this.UserPracticeTestId)
          .then((data) => {
            // debugger        
            this.globals.isLoading = false;
            this.categoryList = {};
            this.categoryList = data;
            var count = 0;
            for (var i = 0; i < this.categoryList.length; i++) {
              if (this.categoryList[i].CategoryStatusId == 1) {
                count++;
              }
            }
            if (count == this.categoryList.length) {
              this.router.navigate(['/practice-result/' + this.practiceTestListEntity[0].UserPracticeTestId]);
            }
            else {
              $('#practicecategorymodal').modal('show');
            }

          },
            (error) => {
              this.globals.isLoading = false;
              swal({
                type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
                title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
                text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
                showConfirmButton: false,
                timer: 4000
              })
            });

      },
        (error) => {
        });
      }
      else{
        swal.fire({
          type: 'warning',
          title: 'Oops...',
          text: 'Please attempt all the items!',
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonClass: 'theme_btn',
          confirmButtonText: 'Ok'
    
        })
      }
  }

  backdashboard() {
    swal({
      title: this.globals.commonTranslationText.practiceTest.alerts.title,
      text: this.globals.commonTranslationText.practiceTest.alerts.text,
      icon: "warning",
      type: this.globals.commonTranslationText.practiceTest.alerts.type,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: "No"
    }).then((result) => {
      if (result.value) {
        $("#practicecategorymodal").modal('hide');
        this.router.navigate(['/certificateDetails/'+this.route.snapshot.paramMap.get('usercertificateid')]);
      }
      else {

      }
    })
  }

  checkMarkedAsReview(i) {
    //alert(i);
  }

}
