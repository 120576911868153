import { Component, OnInit, ElementRef } from '@angular/core';
import { Globals } from '.././globals';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ProfileService } from '../services/profile.service';
import { PasswordService } from '../services/password.service';
import { AuthService } from '../services/auth.service';
import { debug } from 'util';
declare var $, unescape: any, swal: any, PerfectScrollbar: any;
declare function myInput(): any;
declare var $, Bloodhound: any;
declare var CKEDITOR, PerfectScrollbar, jquery, $: any;
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  passwordEntity: any = {};
  passwordSubmit: boolean;
  btn_disable: boolean;
  oldnewsame: boolean = false;
  newconfsame: boolean = false;

  constructor(public globals: Globals, private router: Router, private route: ActivatedRoute, private authService: AuthService, private elem: ElementRef,
    private ProfileService: ProfileService, private PasswordService: PasswordService) { }

  profileEntity;
  ReceiptEntity;
  InvoiceEntity;
  orderHistorylist;
  submitted1;
  submitted2;
  submitted3;
  submitted4;
  submitted5;
  countryList;
  stateList;
  documentList;
  certificateName;
  documentEntity;
  documentDetail;
  FilterEntity;
  Statuslist;
  PersonalDocuments;
  UserPersonalDocuments;
  DocumentValid;
  DocumentDis;
  certificatedocument;
  certificateimage;
  certificatedocumentEntity;
  certiDocumentDis;
  ngOnInit() {
    debugger
    this.certificatedocumentEntity = {};
    this.profileEntity = {};
    this.documentEntity = {};
    this.ReceiptEntity = {};
    this.InvoiceEntity = {};
    this.FilterEntity = {};
    this.documentDetail = [];
    this.orderHistorylist = [];
    this.Statuslist = [];
    this.PersonalDocuments = [];
    this.UserPersonalDocuments = [];
    this.certificateimage = [];
    this.certificatedocument = [];
    this.countryList = [];
    this.stateList = [];
    
    this.globals.isLoading = true;

    var id = this.globals.authData.UserId;

    var addressadd = this.route.snapshot.paramMap.get('addressadd');
    if (addressadd == 'addressadd') {
      $("#address-info-tab").addClass("active");
      $("#personal-info-tab").removeClass("active");

      $("#address-info").addClass("show active")
      $("#personal-info").removeClass("show active")

    }
    if (addressadd == 'document') {
      $("#document-tab").addClass("active");
      $("#personal-info-tab").removeClass("active");

      $("#document").addClass("show active")
      $("#personal-info").removeClass("show active")

    }
    this.ProfileService.getAllDefault(id)
      .then((data) => {
        debugger
        this.globals.isLoading = false;
        //this.countryList = data['Countries'];
        var countrySelect =  {
          CountryId:'',
          CountryName: this.globals.commonTranslationText.profilePage.addressInformationForm.country.select,
          Value: ""
        }
        this.countryList.push(countrySelect);
        this.countryList = [...this.countryList,...data['Countries']];
        //this.stateList = data['States'];
        this.orderHistorylist = data['orderHistory'];
        //this.Statuslist = data['Configuration'];
        var statusSelect =  {
          ConfigurationId:'',
          DisplayText: "Select Status",
          Value: ""
        }
        this.Statuslist.push(statusSelect);
        this.Statuslist = [...this.Statuslist,...data['Configuration']];

        this.UserPersonalDocuments = data['UserPersonalDocuments'];
        //this.PersonalDocuments = data['PersonalDocuments'];
        var personalSelect =  {
          DocumentId:'',
          DocumentName: this.globals.commonTranslationText.profilePage.documents.select,
          Value: ""
        }
        this.PersonalDocuments.push(personalSelect);
        this.PersonalDocuments = [...this.PersonalDocuments,...data['PersonalDocuments']];

        this.profileEntity = data['UserData'][0];
        console.log(this.PersonalDocuments);
        this.countProgressBar();
        for (var i = 0; i < this.UserPersonalDocuments.length; i++) {
          var ExtStr = this.UserPersonalDocuments[i].CertificateDocumentName;
          var Ext = ExtStr.substring(ExtStr.length - 4, ExtStr.length);
          this.UserPersonalDocuments[i].Ext = Ext;
        }

        var Certificate;
        for (Certificate of this.profileEntity.CertificateData) {
          var Documents;
          var Mandatory;
          for (Documents of Certificate.OptionalDocuments) {
            if (Certificate.OptionalDocuments) {
              var ExtStr = Documents.CertificateDocumentName;
              var Ext = ExtStr.substring(ExtStr.length - 4, ExtStr.length);
              Documents.Ext = Ext;
            }
          }
          for (Mandatory of Certificate.MandatoryDocuments) {
            if (Mandatory) {
              var ExtStr = Mandatory.CertificateDocumentName;
              var Ext = ExtStr.substring(ExtStr.length - 4, ExtStr.length);
              Mandatory.Ext = Ext;

            }
          }
        }
        var UserPersonal
        for (UserPersonal of this.UserPersonalDocuments) {
          var PersonalDocuments
          for (PersonalDocuments of this.PersonalDocuments) {
            if (PersonalDocuments.DocumentId == UserPersonal.DocumentId) {
              PersonalDocuments.flag = 1;
            }
          }
        }

        // for (var j = 0; j < this.profileEntity.CertificateData.length; j++)
        // {
        //   for (var k = 0; k < this.profileEntity.CertificateData[j].OptionalDocuments.length; k++)
        //   {
        //     var ExtStr = this.profileEntity[0].CertificateData[j].OptionalDocuments[k].CertificateDocumentName;
        //     var Ext = ExtStr.substring(ExtStr.length - 4, ExtStr.length);
        //     this.profileEntity[0].CertificateData[j].OptionalDocuments[k].Ext = Ext;
        //    console.log(this.profileEntity[i].CertificateData[j].OptionalDocuments[k]);
        //     alert(this.profileEntity[0].CertificateData[j].OptionalDocuments[k].Ext);
        //   }

        // for (var l = 0; l < this.profileEntity[0].CertificateData[j].MandatoryDocuments.length; l++)
        // {
        //   var ExtStr = this.profileEntity[0].CertificateData[j].MandatoryDocuments[l].CertificateDocumentName;
        //   var Ext = ExtStr.substring(ExtStr.length - 4, ExtStr.length);
        //   this.profileEntity[0].CertificateData[j].MandatoryDocuments[l].Ext = Ext;
        // //  console.log(this.profileEntity[i].CertificateData[j].MandatoryDocuments[l].Ext);
        //   alert(this.profileEntity[0].CertificateData[j].MandatoryDocuments[l].Ext);

        // }
        // }


        setTimeout(function () {
          $('select').selectpicker();
        }, 5000);
        // this.profileEntity[0].CountryId = '';
        // this.profileEntity[0].StateId = '';

        for(var i=0;i<this.orderHistorylist.length;i++)
        {
          if (this.orderHistorylist[i].CertificateEndDate == null) {
            if (this.orderHistorylist[i].OrderDate == null)
              var someDate = new Date();
            else
              var someDate = new Date(this.orderHistorylist[i].OrderDate);
            var numberOfDaysToAdd = JSON.parse(this.orderHistorylist[i].AssessmentDuration);
            someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
  
            var dd = someDate.getDate();
            var mm = someDate.getMonth() + 1;
            var y = someDate.getFullYear();
  
            this.orderHistorylist[i].assessmentExpirationDate = y + '-' + mm + '-' + dd;
          }
        }
        console.log(this.orderHistorylist);

        if (this.profileEntity.StateId != '') {
          this.ProfileService.getStateByCountryId(this.profileEntity.CountryId)
            .then((data) => {
              //this.stateList = data;
              var data1 :any;
              data1 = data;
              var stateSelect =  {
                StateId:'',
                StateName: this.globals.commonTranslationText.profilePage.addressInformationForm.state.select,
                Value: ""
              }
              this.stateList.push(stateSelect);
              this.stateList = [...this.stateList,...data1];

              setTimeout(function () {
                $('#StateId').selectpicker('refresh');
              }, 1000);
            },
              (error) => {
                this.btn_disable = false;
                this.submitted2 = false;
              });
        }

      },
        (error) => {
          swal({
            type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
            title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
            text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
            showConfirmButton: false,
            timer: 4000
          })
          this.globals.isLoading = false;
          this.btn_disable = false;
          this.passwordSubmit = false;
        });
    setTimeout(function () {

      $('.file_upload input[type="file"]').change(function (e) {
        var fileName = e.target.files[0].name;
        $('.file_upload input[type="text"]').val(fileName);
      });
    }, 5000);
    // setTimeout(function () {
    //   $('select').selectpicker();
    // }, 1000);
    setTimeout(function () {

      $(document).ready(function () {

        $("#editimage").change(function (event) {
          $('#editprofile_img').show();
          readURLedit(this);
        });
        $('#editprofile_img').hide();
        function readURLedit(input) {
          if (input.files && input.files[0]) {
            var reader = new FileReader();
            var filename = $("#editimage").val();
            filename = filename.substring(filename.lastIndexOf('\\') + 1);
            reader.onload = function (e) {
              $('#user_img').attr('src', event.target["result"]);
              $('#user_img').hide();
              $('#user_img').fadeIn(500);
            }
            reader.readAsDataURL(input.files[0]);
          }
        }
        //this.countProgressBar();
        $("#editprofile_img").click(function () {
          $("#editimage").val('');
          $('#user_img').attr('src', 'assets/images/placeholder.png');
          $('#editprofile_img').hide();
        });
      });

      $("#new_upload_file").change(function (event) {
        $('.uploaded_doc_block').show();
        $('.new_block').hide();
        readURLedit(this);
      });
      $('.uploaded_doc_block').hide();
      function readURLedit(input) {
        if (input.files && input.files[0]) {
          var reader = new FileReader();
          var filename = $("#new_upload_file").val();
          filename = filename.substring(filename.lastIndexOf('\\') + 1);
          reader.onload = function (e) {
            $('#uploaded_doc').attr('src', event.target["result"]);
          }
          reader.readAsDataURL(input.files[0]);
        }
      }
      $("#deletedoc_img").click(function () {
        $("#new_upload_file").val('');
        $('#uploaded_doc').attr('src', '');
        $('.uploaded_doc_block').hide();
        $('.new_block').show();
      });

      // Add minus icon for collapse element which is open by default
      $(".collapse.show").each(function () {
        $(this).prev(".card-header").find(".plus_minus_acc").addClass("fa-minus").removeClass("fa-plus");
      });

      // Toggle plus minus icon on show hide of collapse element
      $(".collapse").on('show.bs.collapse', function () {
        $(this).prev(".card-header").find(".plus_minus_acc").removeClass("fa-plus").addClass("fa-minus");
      }).on('hide.bs.collapse', function () {
        $(this).prev(".card-header").find(".plus_minus_acc").removeClass("fa-minus").addClass("fa-plus");
      });
    }, 100);
    $("#oldpassword-show").click(function () {
      $(this).toggleClass("fa-eye fa-eye-slash")
      if ($("#oldpassword").attr("type") == "password") {
        $("#oldpassword").attr("type", "text");

      }
      else {
        $("#oldpassword").attr("type", "password");
      }
    });

    $("#password-show").click(function () {
      $(this).toggleClass("fa-eye fa-eye-slash")
      if ($("#password").attr("type") == "password") {
        $("#password").attr("type", "text");

      }
      else {
        $("#password").attr("type", "password");
      }
    });

    $("#confpassword-show").click(function () {
      $(this).toggleClass("fa-eye fa-eye-slash")
      if ($("#confpassword").attr("type") == "password") {
        $("#confpassword").attr("type", "text");

      }
      else {
        $("#confpassword").attr("type", "password");
      }
    });
    setTimeout(function () {
      // $('select').selectpicker();
      $('input[name="OrderDateFrom"]').daterangepicker({
        autoUpdateInput: false,
        locale: {
          cancelLabel: 'Clear'
        }
      });
      $('input[name="OrderDateFrom"]').on('apply.daterangepicker', function (ev, picker) {
        $(this).val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
      });

      $('input[name="OrderDateFrom"]').on('cancel.daterangepicker', function (ev, picker) {
        $(this).val('');
      });
    }, 1000);
  }

  ConvertToWord(num) {
    var a = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
    var b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
    var n = [];

    if ((num = num.toString()).length > 9) return 'overflow';
    n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return; var str = '';
    str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
    str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
    str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
    str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
    str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'only ' : '';
    return str;

  }

  printInvoice(id) {
    this.ProfileService.getOrderInvoice(id)
      .then((data) => {
        this.InvoiceEntity = data;

        console.log(data);
        setTimeout(function () {
          var innerContents = document.getElementById('printInvoice').innerHTML;
          var popupWinindow = window.open('', '_blank', 'width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
          popupWinindow.document.open();
          popupWinindow.document.write(innerContents);
          popupWinindow.document.close();
          popupWinindow.print();
          popupWinindow.close();
        }, 1000);

      },
        (error) => {
          this.btn_disable = false;
          this.submitted2 = false;
        });
  }
  changepwsd() {
    this.passwordSubmit = false;
    this.submitted2 = false;
  }
  printReceipt(id) {
    debugger
    this.ProfileService.getOrderReceipt(id)
      .then((data) => {
        this.ReceiptEntity = data;
        var Num = Number(this.ReceiptEntity.Amount);
        this.ReceiptEntity.Amountword = this.ConvertToWord(Num);
        setTimeout(function () {
          var innerContents = document.getElementById('printeReceipt').innerHTML;
          var popupWinindow = window.open('', '_blank', 'width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
          popupWinindow.document.open();
          popupWinindow.document.write(innerContents);
          popupWinindow.document.close();
          popupWinindow.print();
          popupWinindow.close();
        }, 1000);

      },
        (error) => {
          this.btn_disable = false;
          this.submitted2 = false;
        });

  }
  documents(UserDocument) {
    debugger
    if (UserDocument) {
      this.DocumentDis = true;
      //$("#CertificateDocumentId").val(13);
      var aa = UserDocument.DocumentId;
      this.documentEntity.UserDocumentId = UserDocument.UserDocumentId;
      this.documentEntity.CertificateDocumentId = UserDocument.DocumentId;
      setTimeout(() => {

        $("#CertificateDocumentId").selectpicker('refresh');
        $("#CertificateDocumentId").val(aa);

      }, 200);
      //  this.documentEntity.CertificateDocumentId=UserDocument.DocumentId;


    } else {
      this.submitted3 = false;
      this.DocumentDis = false;
      this.documentEntity.UserDocumentId = 0;
      setTimeout(() => {
        $("#CertificateDocumentId").selectpicker('refresh');
      }, 200);
    }
    setTimeout(function () {
      $('#documentModal').modal('show');
      $('select').selectpicker();
    }, 200);
  }
  imageclick(image, i) {
    debugger
    setTimeout(function () {
      $('input[type="checkbox"]').on('change', function () {
        $('input[type="checkbox"]').not(this).prop('checked', false);
      });
      $("#new_upload_file").val('');
      $('#uploaded_doc').attr('src', '');
      $('.uploaded_doc_block').hide();
      $('.new_block').show();
    });
    this.certificatedocumentEntity.UserDocumentId = image.UserDocumentId;
  }
  Removeimage() {
    // this.certificatedocumentEntity.UserDocumentId = 0;
    // $('.radio_box input[type="radio"]').val(null);
    // // setTimeout(function () {
    // //   $('.radio_box input[type="radio"]').val(false);
    // //  },);

    $("#new_upload_file").val('');
    $('#uploaded_doc').attr('src', '');
    $('.uploaded_doc_block').hide();
    $('.new_block').show();
    this.certificatedocumentEntity.CertificateName = '';

  }
  
  fileTypeCheck(file,CertiDocumentId)
  {
    this.certificatedocumentEntity.UserDocumentId = 0;
    $('.check_box input[name="document_select"]').prop('checked', false);
    if(CertiDocumentId != '' && CertiDocumentId != undefined)
    {
      for (var j = 0; j < this.certificatedocument.length; j++) {
        if(this.certificatedocument[j].DocumentId == CertiDocumentId)
        {
          var arrayExtensions = this.certificatedocument[j].DocumentType;
        }
      }
      var ext = file.split(".");
      ext = "."+ext[ext.length-1].toLowerCase();      
      //var arrayExtensions = ["jpg" , "jpeg", "png", "bmp", "gif"];

      if (arrayExtensions.lastIndexOf(ext) == -1) {
        swal({
          type: 'warning',
          title: 'Wrong Extensions',
          text: 'You can not upload file that extensions with ' + ext,
          showConfirmButton: false,
          timer: 3000
        })
        this.certificatedocumentEntity.CertificateName = '';
        setTimeout(function () {
          $("#new_upload_file").val('');
          $('#uploaded_doc').attr('src', '');
          $('.uploaded_doc_block').hide();
          $('.new_block').show();
        },200);
      }
    }
    else{
      swal({
        type: 'warning',
        title: 'Select Document',
        text: 'Please select a document',
        showConfirmButton: false,
        timer: 3000,
      })
      setTimeout(function () {
        $("#new_upload_file").val('');
        $('#uploaded_doc').attr('src', '');
        $('.uploaded_doc_block').hide();
        $('.new_block').show();
      },200);
    }
    
  }
  certificatedocuments(certificates, certificateDocument) {
    debugger
    if (certificateDocument) {
      this.certificatedocumentEntity.UserDocumentId = certificateDocument.UserDocumentId;
      this.certificatedocumentEntity.CertiDocumentId = certificateDocument.DocumentId;
      this.certiDocumentDis = true;
      this.DocumentDis = true;
      //$("#CertificateDocumentId").val(13);
      var aa = certificates.DocumentId;
      setTimeout(() => {
        $("#CertiDocumentId").selectpicker('refresh');
        $("#CertiDocumentId").val(aa);

      }, 200);
    } else {
      this.certificatedocumentEntity.UserDocumentId = 0;
      this.certiDocumentDis = false;
      this.Removeimage();
      setTimeout(() => {
        $("#CertiDocumentId").selectpicker('refresh');
        //$("#CertiDocumentId").val('');
        this.submitted5 = false;
        this.certificatedocumentEntity.CertiDocumentId = '';
      }, 200);
    }
    this.certificatedocumentEntity.UserDocumentCertificateMappingId = certificateDocument.UserDocumentCertificateMappingId;
    this.certificatedocumentEntity.UserCertificateId = certificates.UserCertificateId;
    $('#certificateModal').modal('show');

    certificates.UserId = this.globals.authData.UserId;
    this.ProfileService.getData(certificates)
      .then((data) => {
        this.globals.isLoading = false;

        this.certificateimage = data['UserAllDocuments'];
        //this.certificatedocument = data['CertificateDocuments'];
        var documentSelect =  {
          DocumentId:'',
          DocumentName: this.globals.commonTranslationText.profilePage.documents.select,
          Value: ""
        }
        this.certificatedocument.push(documentSelect);
        this.certificatedocument = [...this.certificatedocument,...data['CertificateDocuments']];
        
        console.log(this.certificatedocument);
        var Certificate
        for (Certificate of this.profileEntity.CertificateData) {
          var Documents;
          var Mandatory;
          for (Documents of Certificate.OptionalDocuments) {
            var certificatedocument;
            for (certificatedocument of this.certificatedocument) {
              if (Documents.DocumentId == certificatedocument.DocumentId) {
                certificatedocument.flag = 1;
              }
            }
          }
          for (Mandatory of Certificate.MandatoryDocuments) {
            var certificatedocument;
            for (certificatedocument of this.certificatedocument) {
              if (Mandatory.DocumentId == certificatedocument.DocumentId) {
                certificatedocument.flag = 1;
              }
            }
          }
        }
        setTimeout(function () {
          $("#CertiDocumentId").selectpicker('refresh');
          $('select').selectpicker();

        }, 500);
      },
        (error) => {
          this.btn_disable = false;
          this.submitted1 = false;
          this.globals.isLoading = false;
          swal({
            type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
            title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
            text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
            showConfirmButton: false,
            timer: 4000
          })
        });

  }

  countProgressBar() {
    debugger
    var current_progress = 0;

    if (this.profileEntity.FirstName != '' && this.profileEntity.FirstName != null) {
      current_progress += 16.67;
    }
    if (this.profileEntity.LastName != '' && this.profileEntity.LastName != null) {
      current_progress += 16.66;
    }
    if (this.profileEntity.PhoneNumber != '' && this.profileEntity.PhoneNumber != null) {
      current_progress += 16.67;
    }
    if (this.profileEntity.Address1 != '' && this.profileEntity.Address1 != null) {
      current_progress += 10;
    }
    if (this.profileEntity.ZipCode != '' && this.profileEntity.ZipCode != null) {
      current_progress += 10;
    }
    if (this.profileEntity.City != '' && this.profileEntity.City != null) {
      current_progress += 10;
    }
    if (this.profileEntity.CountryId != '' && this.profileEntity.CountryId != null) {
      current_progress += 10;
    }
    if (this.profileEntity.StateId != '' && this.profileEntity.StateId != null) {
      current_progress += 10;
    }
    //this.globals.current_progress = current_progress;

    //console.log(current_progress/100);
    $('.circle').circleProgress({
      value: current_progress/100,
      size: 70.0,
      startAngle: -Math.PI,
      emptyFill: '#ccc',
      fill: { color: '#1bc943' }
    }).on('circle-animation-progress', function (event, progress) {
      $(this).find('strong').html(current_progress+'%');
    });
  }

  profile1(profileForm1) {
    this.submitted1 = true;
    if (profileForm1.valid) {
      this.btn_disable = true;
      this.globals.isLoading = true;
      this.profileEntity.UserId = this.globals.authData.UserId;
      this.ProfileService.editprofile(this.profileEntity)
        .then((data) => {
          this.globals.isLoading = false;
          this.btn_disable = false;
          this.submitted1 = false;
          swal({
            type: this.globals.commonTranslationText.profilePage.alerts.profile.type,
            title: this.globals.commonTranslationText.profilePage.alerts.profile.title,
            text: this.globals.commonTranslationText.profilePage.alerts.profile.text,
            showConfirmButton: false,
            timer: 4000
          })
          this.countProgressBar();
					/*if (profileImage) {
						this.EditProfileService.uploadProfilePicture(profilefd, this.globals.authData.UserId)
							.then((data) => {
								$("#UsereditImageId").val(null);
							},
								(error) => {
									this.btn_disable = false;
									this.submitted = false;
									this.globals.isLoading = false;
									this.router.navigate(['/pagenotfound']);
								});
          }*/
        },
          (error) => {
            this.btn_disable = false;
            this.submitted1 = false;
            this.globals.isLoading = false;
            swal({
              type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
              title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
              text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
              showConfirmButton: false,
              timer: 4000
            })
          });
    }
  }
  getStateListadd(addressForm) {
    debugger
    addressForm.form.controls.StateId.markAsDirty();
    this.profileEntity.StateId = '';

    this.stateList = [];
    if (this.profileEntity.CountryId > 0) {
      this.ProfileService.getStateByCountryId(this.profileEntity.CountryId)
        .then((data) => {
          //this.stateList = data;
          var data1 : any;
          data1 = data;
          var stateSelect =  {
            StateId:'',
            StateName: this.globals.commonTranslationText.profilePage.addressInformationForm.state.select,
            Value: ""
          }
          this.stateList.push(stateSelect);
          this.stateList = [...this.stateList,...data1];
          setTimeout(function () {
            $('#StateId').selectpicker('refresh');
          }, 500);
        },
          (error) => {
            this.btn_disable = false;
            this.submitted2 = false;
          });
    } else {
      this.stateList = [];
    }
  }

  address(addressForm) {
    this.submitted2 = true;
    if (addressForm.valid) {
      this.btn_disable = true;
      this.globals.isLoading = true;
      this.profileEntity.UserId = this.globals.authData.UserId;
      if (this.profileEntity.AddressId == null) {
        this.profileEntity.AddressId = 0;
      }
      this.ProfileService.editaddress(this.profileEntity)
        .then((data) => {
          this.globals.isLoading = false;
          this.btn_disable = false;
          this.submitted2 = false;
          swal({
            type: this.globals.commonTranslationText.profilePage.alerts.address.type,
            title: this.globals.commonTranslationText.profilePage.alerts.address.title,
            text: this.globals.commonTranslationText.profilePage.alerts.address.text,
            showConfirmButton: false,
            timer: 4000
          })
          this.countProgressBar();
        },
          (error) => {
            this.btn_disable = false;
            this.submitted1 = false;
            this.globals.isLoading = false;
            swal({
              type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
              title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
              text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
              showConfirmButton: false,
              timer: 4000
            })
          });
    }
  }

  changePassword(changePasswordForm) {
    this.passwordSubmit = true;
    if (changePasswordForm.valid && !this.newconfsame && !this.oldnewsame) {
      this.passwordEntity.UserId = this.globals.authData.UserId;
      this.btn_disable = true;
      this.globals.isLoading = true;
      this.PasswordService.changePassword(this.passwordEntity)
        .then((data) => {
          this.globals.isLoading = false;
          this.btn_disable = false;
          this.passwordSubmit = false;
          this.passwordEntity = {};
          changePasswordForm.form.markAsPristine();
          swal({
            type: this.globals.commonTranslationText.profilePage.alerts.changePasswordSuccess.type,
            title: this.globals.commonTranslationText.profilePage.alerts.changePasswordSuccess.title,
            text: this.globals.commonTranslationText.profilePage.alerts.changePasswordSuccess.text,
            showConfirmButton: false,
            timer: 2000
          })
        },
          (error) => {
            if (error.status == 400) {
              swal({
                type: this.globals.commonTranslationText.profilePage.alerts.wrongOldPassword.type,
                title: this.globals.commonTranslationText.profilePage.alerts.wrongOldPassword.title,
                text: this.globals.commonTranslationText.profilePage.alerts.wrongOldPassword.text,
                showConfirmButton: false,
                timer: 4000
              })
            } else {
              swal({
                type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
                title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
                text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
                showConfirmButton: false,
                timer: 4000
              })
            }
            this.globals.isLoading = false;
            this.btn_disable = false;
            this.passwordSubmit = false;
          });
    }
  }

  confirmPassword() {
    if (this.passwordEntity.OldPassword == this.passwordEntity.Password) {
      this.oldnewsame = true;
      this.newconfsame = false;
    } else {
      this.oldnewsame = false;
      if (this.passwordEntity.ConfirmPassword != this.passwordEntity.Password) {
        if (this.passwordEntity.ConfirmPassword != null) {
          this.newconfsame = true;
        }
        else {
          this.newconfsame = false;
        }
      }
      else {
        this.newconfsame = false;
      }
    }
    if (this.passwordEntity.OldPassword == '') {
      this.oldnewsame = false;
      this.newconfsame = false;
    }
    if (this.passwordEntity.Password == '' || this.passwordEntity.ConfirmPassword == '') {
      this.newconfsame = false;
    }
  }

  // countProgressBar() {
  //   var current_progress = 0;
  //   if (this.profileEntity.FirstName != '' && this.profileEntity.FirstName != null) {
  //     current_progress += 5;
  //   }

  //   this.globals.current_progress = current_progress;
  //   setTimeout(function () {
  //     $(".progress-bar")
  //       .css("width", current_progress + "%")
  //       .attr("aria-valuenow", current_progress)


  //   }, 1000);
  // }


  documentSubmit(documentForm) {
    debugger

    this.submitted3 = true;
    var count = 0;
    if (this.documentEntity.CertificateDocumentName == "" || this.documentEntity.CertificateDocumentName == null || this.documentEntity.CertificateDocumentName == undefined) {
      this.DocumentValid = true;
      count = 1;
    } else {
      this.DocumentValid = false;
    }
    if (documentForm.valid) {
      let file1 = '';
      var fd = new FormData();
      var total = 0;
      this.documentEntity.Document = [];

      if (this.documentEntity.CertificateDocumentId != '' && this.documentEntity.CertificateDocumentName != '') {

        file1 = this.elem.nativeElement.querySelector('#CertificateDocumentName').files;

        if (file1 && file1.length != 0) {
          total = file1.length;
          for (var k = 0; k < file1.length; k++) {
            var Images = Date.now() + '_' + file1[k]['name'];
            fd.append('Document' + k, file1[k], Images);
            this.documentEntity.Document.push(Images);
            this.documentEntity.CertificateDocumentName = Images;
          }
        }
        else {
          fd.append('Document', null);
          this.documentEntity.Document = null;
        }
      }
      //CertificateName,UserName
      this.documentEntity.UserDocuments = [{
        UserDocumentId: this.documentEntity.UserDocumentId,
        DocumentId: this.documentEntity.CertificateDocumentId,
        CertificateDocumentName: this.documentEntity.CertificateDocumentName,
        DocumentUrl: '/assests/Documents/',
        UserId: this.globals.authData.UserId,
        documentCount: 1,
        UserName: this.globals.authData.FirstName + ' ' + this.globals.authData.LastName,
        CertificateName: this.certificateName, LoginURL: '/login'
      }];
      //console.log(this.documentEntity.UserDocuments);

      this.globals.isLoading = true;
      this.ProfileService.addDocument(this.documentEntity)
        .then((data) => {
          this.globals.isLoading = false;
          this.btn_disable = false;
          this.submitted3 = false;
          if (file1) {
            this.ProfileService.uploadFileCertificate(fd, total, this.globals.authData.UserId)
              .then((data) => {
                $("#CertificateDocumentName").val(null);
                $("#documentModal").hide();
                swal({
                  type: this.globals.commonTranslationText.profilePage.alerts.document.type,
                  title: this.globals.commonTranslationText.profilePage.alerts.document.title,
                  text: this.globals.commonTranslationText.profilePage.alerts.document.text,
                  showConfirmButton: false,
                  timer: 3000
                })
                window.location.href = "/profile/document";
                //this.router.navigate(['/profile']);	
              },
                (error) => {
                  this.btn_disable = false;
                  this.globals.isLoading = false;
                });
          }
          else {
            swal({
              type: this.globals.commonTranslationText.profilePage.alerts.document.type,
              title: this.globals.commonTranslationText.profilePage.alerts.document.title,
              text: this.globals.commonTranslationText.profilePage.alerts.document.text,
              showConfirmButton: false,
              timer: 3000
            })
            //this.router.navigate(['/profile']);
            window.location.href = "/profile/document";
          }
        },
          (error) => {
            this.btn_disable = false;
            this.submitted1 = false;
            this.globals.isLoading = false;
            swal({
              type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
              title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
              text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
              showConfirmButton: false,
              timer: 4000
            })
          });
    }
  }

  deletePersonalDocument(UserPersonal) {
    // alert(UserPersonal.UserDocumentId);
    debugger
    /*let index = this.profileEntity.CertificateData[i].documents.indexOf(j);
              if (index != -1) {
                this.profileEntity.CertificateData[i].documents.splice(index, 1);
              }*/
    swal({
      title: this.globals.commonTranslationText.profilePage.alerts.deleteConfirm.title,
      text: this.globals.commonTranslationText.profilePage.alerts.deleteConfirm.text,
      icon: "warning",
      type: this.globals.commonTranslationText.profilePage.alerts.deleteConfirm.type,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: "No"
    })
      .then((result) => {
        if (result.value) {
          this.globals.isLoading = true; debugger
          this.ProfileService.deletePersonalDocument(UserPersonal.UserDocumentId)
            .then((data) => {
              let index = this.UserPersonalDocuments.indexOf(UserPersonal);
              if (index != -1) {
                this.UserPersonalDocuments.splice(index, 1);
              }
              this.globals.isLoading = false;

              swal({
                type: this.globals.commonTranslationText.profilePage.alerts.deleteSuccess.type,
                title: this.globals.commonTranslationText.profilePage.alerts.deleteSuccess.title,
                text: this.globals.commonTranslationText.profilePage.alerts.deleteSuccess.text,
                showConfirmButton: false,
                timer: 4000
              })
            },
              (error) => {
                this.globals.isLoading = false;
                if (error.text) {
                  swal({
                    //position: 'top-end',
                    type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
                    title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
                    text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
                  })
                }
                if (error.error.message == "Already in use") {
                  swal({
                    //position: 'top-end',
                    type: this.globals.commonTranslationText.profilePage.alerts.alreadyUseDocument.type,
                    title: this.globals.commonTranslationText.profilePage.alerts.alreadyUseDocument.title,
                    text: this.globals.commonTranslationText.profilePage.alerts.alreadyUseDocument.text,
                    showConfirmButton: false,
                    timer: 4000
                  })
                }
              });
          //window.location.href = "/profile";
          //this.router.navigate(['/profile']);
        }
      })
  }

  deleteDocument(certificateDocument,certificates,value) {
    // alert(UserPersonal.UserDocumentId);
    debugger
    /*let index = this.profileEntity.CertificateData[i].documents.indexOf(j);
              if (index != -1) {
                this.profileEntity.CertificateData[i].documents.splice(index, 1);
              }*/
    swal({
      title: this.globals.commonTranslationText.profilePage.alerts.deleteConfirm.title,
      text: this.globals.commonTranslationText.profilePage.alerts.deleteConfirm.text,
      icon: "warning",
      type: this.globals.commonTranslationText.profilePage.alerts.deleteConfirm.type,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: "No"
    })
      .then((result) => {
        if (result.value) {
          console.log(certificates);
          this.globals.isLoading = true; debugger
          this.ProfileService.deleteDocument(certificateDocument.UserDocumentId,certificateDocument.UserDocumentCertificateMappingId)
            .then((data) => {
              // if(value == 0)
              // {
              //   let index = certificates.OptionalDocuments.indexOf(certificateDocument);
              //   if (index != -1) {
              //     certificates.OptionalDocuments.splice(index, 1);
              //   }
              // }
              // else{
              //   let index = certificates.MandatoryDocuments.indexOf(certificateDocument);
              //   if (index != -1) {
              //     certificates.MandatoryDocuments.splice(index, 1);
              //   }
              // }
              this.globals.isLoading = false;

              swal({
                type: this.globals.commonTranslationText.profilePage.alerts.deleteSuccess.type,
                title: this.globals.commonTranslationText.profilePage.alerts.deleteSuccess.title,
                text: this.globals.commonTranslationText.profilePage.alerts.deleteSuccess.text,
                showConfirmButton: false,
                timer: 4000
              })
              
              window.location.href = "/profile/document";
            },
              (error) => {
                this.globals.isLoading = false;
                if (error.text) {
                  swal({
                    //position: 'top-end',
                    type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
                    title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
                    text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
                  })
                }
                if (error.error.message == "Already in use") {
                  swal({
                    //position: 'top-end',
                    type: this.globals.commonTranslationText.profilePage.alerts.alreadyUseDocument.type,
                    title: this.globals.commonTranslationText.profilePage.alerts.alreadyUseDocument.title,
                    text: this.globals.commonTranslationText.profilePage.alerts.alreadyUseDocument.text,
                    showConfirmButton: false,
                    timer: 4000
                  })
                }
              });
          //this.router.navigate(['/profile']);
        }
      })
  }
  SearchFilter(SearchFilterForm) {
    debugger
    this.globals.isLoading = true;
    // var str = $("#OrderDateFrom").val();
    // var res = str.split(" ");

    // if (res[0] == "") {
    //   this.FilterEntity.OrderDateFrom = "";
    //   this.FilterEntity.OrderDateTo = "";
    // } else {
    //   this.FilterEntity.OrderDateFrom = res[0];
    //   this.FilterEntity.OrderDateTo = res[2];
    // }
    if(this.FilterEntity.OrderDateFrom != undefined){
      var d = new Date(this.FilterEntity.OrderDateFrom);
      var OrderFromMonth = d.getMonth() + 1;
      var OrderFromDate = d.getDate();
      var OrderFromYear = d.getFullYear();
      this.FilterEntity.OrderDateFrom = OrderFromYear  + '/' + (OrderFromMonth < 10 ? '0'+OrderFromMonth: ''+OrderFromMonth) + '/' + ((OrderFromDate < 10 ? '0'+OrderFromDate: ''+OrderFromDate));
    }
    
    if(this.FilterEntity.OrderDateTo != undefined){
    var d1 = new Date(this.FilterEntity.OrderDateTo);
    var OrderToMonth = d1.getMonth() + 1;
    var OrderToDate = d1.getDate();
    var OrderToYear = d1.getFullYear();
    this.FilterEntity.OrderDateTo = OrderToYear  + '/' + (OrderToMonth < 10 ? '0'+OrderToMonth: ''+OrderToMonth) + '/' + ((OrderToDate < 10 ? '0'+OrderToDate: ''+OrderToDate));
    }

    // this.FilterEntity.PaymentStatus = $("#statusid").val();
    // if(this.FilterEntity.PaymentStatus=="" || this.FilterEntity.PaymentStatus==undefined)
    // {
    //   this.FilterEntity.PaymentStatus='';
    // }else
    // {
    // this.FilterEntity.PaymentStatus;
    // }
    this.FilterEntity.UserId = this.globals.authData.UserId;
    this.ProfileService.searchOrderHistory(this.FilterEntity)
      //.map(res => res.json())
      .then((data) => {

        this.orderHistorylist = data;
        this.globals.isLoading = false;
        SearchFilterForm.form.markAsPristine();

      },
        (error) => {
          this.globals.isLoading = false;
          this.router.navigate(['/pagenotfound']);
        });
  }
  clearData(SearchFilterForm) {
    this.globals.isLoading = true;
    this.ProfileService.getAllDefault(this.globals.authData.UserId)
      //.map(res => res.json())
      .then((data) => {
        this.FilterEntity = {};
        this.countProgressBar();

        setTimeout(function () {
          $("#OrderDateFrom").val('');
          //  $('#OrderDateFrom').removeAttr('value');
        }, 100);
        this.orderHistorylist = data['orderHistory'];
        this.globals.isLoading = false;
        SearchFilterForm.form.markAsPristine();

      },
        (error) => {
          this.globals.isLoading = false;
          this.router.navigate(['/pagenotfound']);
        });
  }
  certidocumentSubmit(CertificatedocumentForm) {
    debugger
    //alert(this.certificatedocumentEntity.CertiDocumentId);
    this.submitted5 = true;
    var count = 0;
    var certificate;
    for (certificate of this.certificatedocument) {
      if (this.certificatedocumentEntity.CertiDocumentId == certificate.DocumentId) {
        this.certificatedocumentEntity.DocumentId = certificate.DocumentId;
        this.certificatedocumentEntity.CertificateDocumentId = certificate.CertificateDocumentId;
      }
    }

    if(this.certificatedocumentEntity.CertiDocumentId != '' && this.certificatedocumentEntity.CertiDocumentId != undefined)
    {
      if ((this.certificatedocumentEntity.CertificateName == "" || this.certificatedocumentEntity.CertificateName == null || this.certificatedocumentEntity.CertificateName == undefined) 
          && this.certificatedocumentEntity.UserDocumentId == ''  ) {
        this.DocumentValid = true;
      // count = 1;
      swal({
        type: 'warning',
        title: 'Upload File',
        text: 'Please upload a file or Select any one image',
        showConfirmButton: false,
        timer: 3000
      })
      } else {
        this.DocumentValid = false;
      }
    }
    if (CertificatedocumentForm.valid && !this.DocumentValid) {
      let file1 = '';
      var fd = new FormData();
      var total = 0;
      this.certificatedocumentEntity.Document = [];

      if (this.certificatedocumentEntity.CertificateName != '' && this.certificatedocumentEntity.CertificateName != '') {

        file1 = this.elem.nativeElement.querySelector('#new_upload_file').files;

        if (file1 && file1.length != 0) {
          total = file1.length;
          for (var k = 0; k < file1.length; k++) {
            var Images = Date.now() + '_' + file1[k]['name'];
            fd.append('Document' + k, file1[k], Images);
            this.certificatedocumentEntity.Document.push(Images);
            this.certificatedocumentEntity.CertificateName = Images;
          }
        }
        else {
          fd.append('Document', null);
          this.certificatedocumentEntity.Document = null;
        }
      }
      //CertificateName,UserName
      this.certificatedocumentEntity.UserDocuments = [{
        UserDocumentId: this.certificatedocumentEntity.UserDocumentId,
        DocumentId: this.certificatedocumentEntity.DocumentId,
        UserCertificateId: this.certificatedocumentEntity.UserCertificateId,
        CertificateDocumentId: this.certificatedocumentEntity.CertificateDocumentId,
        UserDocumentCertificateMappingId: this.certificatedocumentEntity.UserDocumentCertificateMappingId,
        CertificateDocumentName: this.certificatedocumentEntity.CertificateName,
        DocumentUrl: 'assests/Documents/',
        UserId: this.globals.authData.UserId,
        documentCount: 1,
        UserName: this.globals.authData.FirstName + ' ' + this.globals.authData.LastName,
        CertificateName: this.certificateName, LoginURL: '/login'
      }];
      console.log(this.certificatedocumentEntity);

      this.globals.isLoading = true;
      this.ProfileService.UpdateCertificateDocuments(this.certificatedocumentEntity)
        .then((data) => {
          this.globals.isLoading = false;
          this.btn_disable = false;
          this.submitted3 = false;
          if (this.certificatedocumentEntity.UserDocumentId != 0) {
            $("#new_upload_file").val(null);
            $("#certificateModal").hide();
            window.location.href = "/profile/document";
          } else {
            if (file1) {
              this.ProfileService.uploadFileCertificate(fd, total, this.globals.authData.UserId)
                .then((data) => {
                  $("#new_upload_file").val(null);
                  $("#certificateModal").hide();
                  swal({
                    type: this.globals.commonTranslationText.profilePage.alerts.document.type,
                    title: this.globals.commonTranslationText.profilePage.alerts.document.title,
                    text: this.globals.commonTranslationText.profilePage.alerts.document.text,
                    showConfirmButton: false,
                    timer: 3000
                  })
                  window.location.href = "/profile/document";
                  //this.router.navigate(['/profile']);	
                },
                  (error) => {
                    this.btn_disable = false;
                    this.globals.isLoading = false;
                  });
            }
            else {
              swal({
                type: this.globals.commonTranslationText.profilePage.alerts.document.type,
                title: this.globals.commonTranslationText.profilePage.alerts.document.title,
                text: this.globals.commonTranslationText.profilePage.alerts.document.text,
                showConfirmButton: false,
                timer: 3000
              })
              //this.router.navigate(['/profile']);
              window.location.href = "/profile/document";
            }
          }
        },
          (error) => {
            this.btn_disable = false;
            this.submitted1 = false;
            this.globals.isLoading = false;
            swal({
              type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
              title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
              text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
              showConfirmButton: false,
              timer: 4000
            })
          });
    }
  }
}
