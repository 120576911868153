import { Component, OnInit } from '@angular/core';
import { Globals } from '.././globals';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { AssessmentPanelService } from '../services/assessment-panel.service';
import { TestBed } from '@angular/core/testing';
declare var $, PerfectScrollbar, swal: any;

@Component({
  selector: 'app-assessment-panel',
  templateUrl: './assessment-panel.component.html',
  styleUrls: ['./assessment-panel.component.css']
})
export class AssessmentPanelComponent implements OnInit {

  assessmentTestListEntity;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  SubmitDisabled;
  TotalUnanswered;
  assessmentTestEntity;
  categoryList;
  addAssessmentTestEntity;
  certificatename;
  categoryname;
  finalSubmitEntity;
  countEntity;
  UserAssessmentId;
  AssessmentTotalItems;
  answerCount;
  resultStatus;
  parentAssessment;
  flag;
  flag1;
  constructor(private router: Router, private route: ActivatedRoute, private AuthService: AuthService, private AssessmentPanelService: AssessmentPanelService, public globals: Globals) { }

  ngOnInit() {
    this.flag = false;
    this.flag1 = false;
    this.assessmentTestListEntity = {};
    this.assessmentTestEntity = {};
    this.categoryList = {};
    this.addAssessmentTestEntity = {};
    this.finalSubmitEntity = {};
    this.countEntity = [];
    this.answerCount = 0;
    var certificateid = this.route.snapshot.paramMap.get('certificateid');
    var usercertificateid = this.route.snapshot.paramMap.get('usercertificateid');
    this.resultStatus = this.route.snapshot.paramMap.get('resultStatus');
    this.parentAssessment = this.route.snapshot.paramMap.get('parentAssessment');
    $('#assessmentcategorymodal').modal('show');

    // setTimeout(function () {


    //   $('.timer').startTimer({
    //     onComplete: function (element) {
    //       alert("saf");
    //     }
    //   });
    // }, 500);
    if (this.UserAssessmentId > 0)
      this.addAssessmentTestEntity.UserAssessmentId = this.UserAssessmentId;
    else
      this.addAssessmentTestEntity.UserAssessmentId = 0;

    var UserAssessmentId = this.route.snapshot.paramMap.get('UserAssessmentId');
    this.AssessmentPanelService.getByCertificateId(certificateid, UserAssessmentId)
      .then((data) => {
        debugger
        this.categoryList = data;
        this.certificatename = data[0].CertificateName;
        console.log(data);
      },
        (error) => {
          this.globals.isLoading = false;
          swal({
            type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
            title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
            text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
            showConfirmButton: false,
            timer: 4000
          })
        });

    this.dropdownList = [
      { item_id: 1, item_text: 'Answered' },
      { item_id: 2, item_text: 'Unanswered' },
      { item_id: 3, item_text: 'Mark as Reviewed' }
    ];
    this.selectedItems = [
      { item_id: 1, item_text: 'Answered' },
      { item_id: 2, item_text: 'Unanswered' },
      { item_id: 3, item_text: 'Mark as Reviewed' }
    ];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

  }
  //  ngAfterViewInit(){
  // if(this.flag){
  // var b = document.querySelector(".timer");
  // b.setAttribute('data-minutes-left', this.addAssessmentTestEntity.CategoryAssessmentTime);
  // $('.timer').startTimer(
  //   setTimeout( ()=>{
  //       swal({
  //         title: 'Oops...',
  //         text: 'Your time is up.Kindly submit your assessment.',
  //         icon: "warning",
  //         type: 'warning',
  //         showCancelButton: true,
  //         confirmButtonColor: '#3085d6',
  //         cancelButtonColor: '#d33',
  //         confirmButtonText: 'Yes',
  //         cancelButtonText: "No"
  //       }).then((result) => {
  //         debugger
  //         if (result.value) {
  //           this.globals.isLoading = true;
  //           this.finalSubmitEntity.TotalCategories = this.categoryList.length;
  //           this.finalSubmitEntity.CertificateId = this.route.snapshot.paramMap.get('certificateid');
  //           this.finalSubmitEntity.UserAssessmentId = this.assessmentTestListEntity[0].UserAssessmentId;
  //           this.finalSubmitEntity.UserId = this.globals.authData.UserId;
  //           this.finalSubmitEntity.UserCertificateId = this.route.snapshot.paramMap.get('usercertificateid');

  //           var certificateid = this.route.snapshot.paramMap.get('certificateid');
  //           this.AssessmentPanelService.AddFinalSubmit(this.finalSubmitEntity)
  //           .then((data1) => {
  //             this.AssessmentPanelService.getByCertificateId(certificateid, this.UserAssessmentId)
  //               .then((data) => {
  //                 debugger
  //                 this.globals.isLoading = false;
  //                 this.categoryList = {};
  //                 this.categoryList = data;
  //                 var count = 0;
  //                 for (var i = 0; i < this.categoryList.length; i++) {
  //                   if (this.categoryList[i].CategoryStatusId == 1) {
  //                     count++;
  //                   }
  //                 }
  //                 if (count == this.categoryList.length) {
  //                   this.router.navigate(['/assessment-result/' + this.assessmentTestListEntity[0].UserAssessmentId]);
  //                 }
  //                 else {
  //                   $('#assessmentcategorymodal').modal('show');
  //                 }
      
  //               },
  //                 (error) => {
  //                   this.globals.isLoading = false;
  //                   swal({
  //                     type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
  //                     title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
  //                     text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
  //                     showConfirmButton: false,
  //                     timer: 4000
  //                   })
  //                 });
  //           },
  //             (error) => {
  //             });
  //         }
  //     })

  //   }, this.addAssessmentTestEntity.CategoryAssessmentTime*60000)
    
  //   )
  // }
  // }
  addPracticeTest(i) {
    if (this.UserAssessmentId > 0)
      this.addAssessmentTestEntity.UserAssessmentId = this.UserAssessmentId;
    else
      this.addAssessmentTestEntity.UserAssessmentId = 0;
    debugger
    this.categoryname = this.categoryList[i].CategoryName;
    this.AssessmentTotalItems = this.categoryList[i].AssessmentTotalItems;
    this.globals.isLoading = true;
    var usercertificateid = this.route.snapshot.paramMap.get('usercertificateid');
    var ScheduleAssessmentId = this.route.snapshot.paramMap.get('ScheduleAssessmentId');
    var HasSubCertificate = this.route.snapshot.paramMap.get('HasSubCertificate');
    this.addAssessmentTestEntity.ScheduleAssessmentId = ScheduleAssessmentId;
    this.addAssessmentTestEntity.UserCertificateId = usercertificateid;
    this.addAssessmentTestEntity.UserId = this.globals.authData.UserId;
    this.addAssessmentTestEntity.CertificateId = this.categoryList[i].CertificateId;
    this.addAssessmentTestEntity.CategoryId = this.categoryList[i].CategoryId;
    this.addAssessmentTestEntity.CertificateAssessmentMappingId = this.categoryList[i].CertificateAssessmentMappingId;
    this.addAssessmentTestEntity.AssessmentTotalItems = this.categoryList[i].AssessmentTotalItems;
    this.addAssessmentTestEntity.NoneScoreItems = this.categoryList[i].NoneScoreItems;
    this.addAssessmentTestEntity.HasSubCertificate = HasSubCertificate;
    this.addAssessmentTestEntity.CategoryAssessmentTime = parseInt(this.categoryList[i].CategoryAssessmentTime);
    console.log(this.addAssessmentTestEntity);
    var certificateid = this.route.snapshot.paramMap.get('certificateid');

    this.AssessmentPanelService.addAssessment(this.addAssessmentTestEntity)
      .then((data) => {
        debugger
        if (data) {
          this.UserAssessmentId = data['UserAssessmentId'];
          var UserAssessmentMappingId = data['UserAssessmentMappingId'];
          this.AssessmentPanelService.getById(UserAssessmentMappingId)
            .then((data1) => {
              debugger
              this.globals.isLoading = false;
              // this.flag = true;
              // if(!this.flag1){
              //   this.ngAfterViewInit();
              // }
              $('#carousel').flexslider('destroy');
              $('#slider').flexslider('destroy');
              $('#assessmentcategorymodal').modal('hide');
              $('.flexslider').removeData('flexslider');
              this.assessmentTestListEntity = {};
              this.assessmentTestListEntity = data1;
              console.log(this.assessmentTestListEntity);
              this.finalSubmitEntity.UserAssessmentMappingId = this.assessmentTestListEntity[0].UserAssessmentMappingId;
              setTimeout(function () {
                $('#carousel').flexslider({
                  animation: "slide",
                  controlNav: false,
                  animationLoop: false,
                  slideshow: false,
                  itemWidth: 60,
                  itemMargin: 0,
                  smoothHeight: true,
                  asNavFor: '#slider'
                });
                $('#slider').flexslider({
                  animation: "slide",
                  controlNav: false,
                  animationLoop: false,
                  slideshow: false,
                  smoothHeight: true,
                  sync: "#carousel"
                });
              }, 500);
              setTimeout(function () {
                // alert($("#slider").find(".flex-direction-nav").length);
                // alert($("#carousel").find(".flex-direction-nav").length);
                $("#carousel .flex-direction-nav").removeAttr("style");
                $("#slider .flex-direction-nav").removeAttr("style");
                $("#carousel .flex-direction-nav").last().css("display", "block");
                $("#slider .flex-direction-nav").last().css("display", "block");
              }, 1000);
            },
              (error) => {
                this.globals.isLoading = false;
                swal({
                  type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
                  title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
                  text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
                  showConfirmButton: false,
                  timer: 4000
                })
              });
              
            }
      },
        (error) => {
          this.globals.isLoading = false;
          swal({
            type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
            title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
            text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
            showConfirmButton: false,
            timer: 4000
          })
        });
  }

  onItemSelect(item: any) {
    //this.filterData();
  }

  onSelectAll(items: any) {
    this.selectedItems = [
      { item_id: 1, item_text: 'Answered' },
      { item_id: 2, item_text: 'Unanswered' },
      { item_id: 3, item_text: 'Mark as Reviewed' }
    ];
    //this.filterData();
  }

  onItemDeSelect(items: any) {
    //this.filterData();
  }

  onDeSelectAll(items: any) {
    this.selectedItems = [];
    //this.filterData();
  }

  k = 1;
  questionitemid = '';
  addAssessmentAnswer(ItemOptionId, CorrectOptionId, AssessmentAnswerId, i, itemid) {
    debugger //alert(ItemOptionId+" "+CorrectOptionId+" "+PracticeTestAnswerId+" "+i)
    if (this.questionitemid == '') {
      this.questionitemid = itemid;
    }
    if (this.questionitemid != '' && this.questionitemid != itemid) {
      this.questionitemid = itemid;
      this.k++;
    }
    if (this.assessmentTestListEntity[i].MarkAsReview) {
      this.assessmentTestListEntity[i].MarkAsReview = 1;
    }
    else{
      this.assessmentTestListEntity[i].MarkAsReview = 0;
    }
    
    $("#questionblock" + (i + 1)).addClass('complete');
    this.assessmentTestEntity.AssessmentAnswerId = AssessmentAnswerId;
    this.assessmentTestEntity.UserItemOptionId = ItemOptionId;
    this.assessmentTestEntity.CorrectOptionId = CorrectOptionId;
    this.assessmentTestEntity.UserId = this.globals.authData.UserId;
    this.assessmentTestEntity.MarkAsReview = this.assessmentTestListEntity[i].MarkAsReview;

    this.AssessmentPanelService.addAssessmentAnswer(this.assessmentTestEntity)
      .then((data1) => {

      },
        (error) => {
          //this.answerCount ++;
        });
    this.countEntity.push(this.assessmentTestEntity);
  }
  markasreview(CorrectOptionId, AssessmentAnswerId, i,ItemId) {
    debugger
    //alert(CorrectOptionId);
    var UserItemOptionId = '';
    //console.log(CorrectOptionId);
    if(this.assessmentTestListEntity[i].UserItemOptionId == null){
      UserItemOptionId = null;
    }
    else{
      UserItemOptionId = this.assessmentTestListEntity[i].UserItemOptionId;
    }
    //$("#questionblock" + (i + 1)).addClass('fa fa-star');
   // alert(CorrectOptionId+" "+AssessmentAnswerId+" "+i+" "+ ItemId);
   //this.addAssessmentAnswer(UserItemOptionId,CorrectOptionId,AssessmentAnswerId,i,ItemId);
   if (this.assessmentTestListEntity[i].MarkAsReview) {
    this.assessmentTestListEntity[i].MarkAsReview = 1;
  }
  else{
    this.assessmentTestListEntity[i].MarkAsReview = 0;
  }
  
  this.assessmentTestEntity.AssessmentAnswerId = AssessmentAnswerId;
  this.assessmentTestEntity.UserItemOptionId = UserItemOptionId;
  this.assessmentTestEntity.CorrectOptionId = CorrectOptionId;
  this.assessmentTestEntity.UserId = this.globals.authData.UserId;
  this.assessmentTestEntity.MarkAsReview = this.assessmentTestListEntity[i].MarkAsReview;

  this.AssessmentPanelService.addAssessmentAnswer(this.assessmentTestEntity)
    .then((data1) => {

    },
      (error) => {
        //this.answerCount ++;
      });
  }
  finalSubmit() {
    debugger
    //TotalCategories  
    this.flag1 = true;
    this.finalSubmitEntity.TotalCategories = this.categoryList.length;
    this.finalSubmitEntity.CertificateId = this.route.snapshot.paramMap.get('certificateid');

    this.finalSubmitEntity.UserAssessmentId = this.assessmentTestListEntity[0].UserAssessmentId;
    this.finalSubmitEntity.UserId = this.globals.authData.UserId;
    this.finalSubmitEntity.UserCertificateId = this.route.snapshot.paramMap.get('usercertificateid');

    var certificateid = this.route.snapshot.paramMap.get('certificateid');
    
    var count = 0;
    for (let i = 0; i < this.assessmentTestListEntity.length; i++) {
      if(this.assessmentTestListEntity[i]['UserItemOptionId']==null || this.assessmentTestListEntity[i]['UserItemOptionId']=='' || this.assessmentTestListEntity[i]['UserItemOptionId']==undefined){
        count++;
      }
    }
    if(count==0){
      this.globals.isLoading = true;
      this.AssessmentPanelService.AddFinalSubmit(this.finalSubmitEntity)
      .then((data1) => {
        this.AssessmentPanelService.getByCertificateId(certificateid, this.UserAssessmentId)
          .then((data) => {
            debugger
            this.globals.isLoading = false;
            this.categoryList = {};
            this.categoryList = data;
            var count = 0;
            for (var i = 0; i < this.categoryList.length; i++) {
              if (this.categoryList[i].CategoryStatusId == 1) {
                count++;
              }
            }
            if (count == this.categoryList.length) {
              this.router.navigate(['/assessment-result/' + this.assessmentTestListEntity[0].UserAssessmentId]);
            }
            else {
              // $('#slider').flexslider('destroy');
              // $('#carousel').flexslider('destroy');
              // $('.flexslider').removeData("flexslider");
              $('#assessmentcategorymodal').modal('show');
              // setTimeout(function () {

              //   $('#carousel').flexslider({
              //     animation: "slide",
              //     controlNav: false,
              //     animationLoop: false,
              //     slideshow: false,
              //     itemWidth: 60,
              //     itemMargin: 0,
              //     asNavFor: '#slider'
              //   });
              //   $('#slider').flexslider({
              //     animation: "slide",
              //     controlNav: false,
              //     animationLoop: false,
              //     slideshow: false,
              //     sync: "#carousel"
              //   });
              // }, 1000);
            }

          },
            (error) => {
              this.globals.isLoading = false;
              swal({
                type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
                title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
                text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
                showConfirmButton: false,
                timer: 4000
              })
            });

      },
        (error) => {
        });
    }
    else{
      swal.fire({
        type: 'warning',
        title: 'Oops...',
        text: 'Please attempt all the items!',
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonClass: 'theme_btn',
        confirmButtonText: 'Ok'
  
      })
    }
  }

  backdashboard() {
    swal({
      title: this.globals.commonTranslationText.practiceTest.alerts.title,
      text: this.globals.commonTranslationText.practiceTest.alerts.text,
      icon: "warning",
      type: this.globals.commonTranslationText.practiceTest.alerts.type,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: "No"
    }).then((result) => {
      if (result.value) {
        $("#assessmentcategorymodal").modal('hide');
        this.router.navigate(['/certificateDetails/'+this.route.snapshot.paramMap.get('usercertificateid')]);
      }
      else {

      }
    })
  }

}
