import { Component, OnInit, ViewChild } from '@angular/core';
import { Globals } from '../globals';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ProctorDashboardService } from '../services/proctor-dashboard.service';
import { DashboardService } from '../app-admin/services/dashboard.service';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { SortDescriptor, process } from '@progress/kendo-data-query';
declare var $, PerfectScrollbar, swal: any;

@Component({
  selector: 'app-attendance-sheet',
  templateUrl: './attendance-sheet.component.html',
  styleUrls: ['./attendance-sheet.component.css']
})
export class AttendanceSheetComponent implements OnInit {

  attendanceSheetList;
  changePresentStatusEntity;
  finalFeedbackEntity;
  resumeAssessmentEntity;
  stopAssessmentEntity;
  documentVerificationEntity;
  mandatoryDocumentsList;
  optionalDocumentsList;
  commentDisplay;
  addressList;
  candidateList;
  proctorList;
  filterEntity;
  submitted1;
  submitted2;
  submitted3;
  submitted4;
  submitted5;
  currentDate;
  addedDate;
  showFilterLocationEntity;
  showFilterShiftEntity;
  showFilterCandidateEntity;
  shiftFilterList;

  constructor(public globals: Globals, private router: Router, private route: ActivatedRoute, private ProctorDashboardService: ProctorDashboardService, private DashboardService: DashboardService) { }

  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  public gridData;

  public mySelection: string[] = [];

  public sort: SortDescriptor[] = [{
    field: 'StartTime',
    dir: 'asc'
  }];

  ngOnInit() {
    debugger
    this.globals.isLoading = true;
    this.attendanceSheetList = [];
    this.changePresentStatusEntity = {};
    this.finalFeedbackEntity = {};
    this.resumeAssessmentEntity = {};
    this.stopAssessmentEntity = {};
    this.mandatoryDocumentsList = [];
    this.optionalDocumentsList = [];
    this.documentVerificationEntity = {};
    this.addressList = [];
    this.shiftFilterList = [];
    this.candidateList = [];
    this.proctorList = [];
    this.filterEntity = {};
    this.showFilterLocationEntity = [];
    this.showFilterShiftEntity = [];
    this.showFilterCandidateEntity = [];


    //this.currentDate = new Date();
    var d = new Date();
    var curr_date = d.getDate();
    var curr_month = d.getMonth() + 1; //Months are zero based
    var curr_year = d.getFullYear();
    this.currentDate = d;//curr_year + '-' + curr_month + '-' + curr_date;
    //     lastEndDate = this.ExtendEvaluatorEntity['EvaluationEndDate'];
    // var newDate1 = new Date(lastEndDate);
    // var newDate = new Date(newDate1.getFullYear(), newDate1.getMonth(), newDate1.getDate());

    // newDate.setDate(newDate.getDate() + 1);
    var dt = new Date();
    dt.setHours(dt.getHours() + 2);
    dt.setMinutes(dt.getMinutes() + 20);
    //alert(dt);
    this.shiftFilterList = [
      { label: "Morning", value: "12:00:00" },
      { label: "Afternoon", value: "17:00:00" },
      { label: "Evening", value: "20:00:00" }
    ];
    if (this.globals.authData.RoleId == 1) {
      debugger
      this.DashboardService.getFilterDefaultData()
        .then((data) => {
          debugger
          this.globals.isLoading = false;
          this.addressList = data['Addresses'];
          this.candidateList = data['Candidates'];
          var proctorSelect = {
            UserId: '',
            FirstName: 'Select Proctor Name',
            Value: ""
          }
          this.proctorList.push(proctorSelect);
          this.proctorList = [...this.proctorList, ...data['Proctors']];
          //this.proctorList = data['Proctors'];          
        },
          (error) => {
            this.globals.isLoading = false;
            if (error.text) {
              swal({
                //position: 'top-end',
                type: 'error',
                title: 'Oops...',
                text: "Something went wrong!"
              })
            }
          });
    } else {
      this.ProctorDashboardService.getFilterDefaultData(this.globals.authData.UserId)
        .then((data) => {
          console.log(data);
          debugger
          this.globals.isLoading = false;
          this.addressList = data['Addresses'];
          this.candidateList = data['Candidates'];
          var proctorSelect = {
            UserId: '',
            FirstName: 'Select Proctor Name',
            Value: ""
          }
          this.proctorList.push(proctorSelect);
          this.proctorList = [...this.proctorList, ...data['Proctors']];
          //this.proctorList = data['Proctors'];          
        },
          (error) => {
            this.globals.isLoading = false;
            if (error.text) {
              swal({
                //position: 'top-end',
                type: 'error',
                title: 'Oops...',
                text: "Something went wrong!"
              })
            }
          });
    }
    if (this.globals.authData.RoleId == 1) {
      this.DashboardService.getFullAttendanceSheet()
        .then((data) => {
          debugger

          this.attendanceSheetList = data;
          this.gridData = data;
          for (let i = 0; i < this.attendanceSheetList.length; i++) {
            var newDate1 = new Date(this.attendanceSheetList[i].AssignDate);
            var hour = this.attendanceSheetList[i].EndTime.substring(0, 2);
            var newDate = new Date(newDate1.getFullYear(), newDate1.getMonth(), newDate1.getDate());
            newDate.setDate(newDate.getDate() + 1);
            newDate.setHours(this.attendanceSheetList[i].EndTime.substring(0, 2), this.attendanceSheetList[i].EndTime.substring(3, 5));
            this.attendanceSheetList[i].addedDate = newDate;

            let hour1 = (this.attendanceSheetList[i].StartTime.split(':'))[0]
            let min = (this.attendanceSheetList[i].StartTime.split(':'))[1]
            let part = hour1 > 12 ? 'pm' : 'am';
            min = (min + '').length == 1 ? `0${min}` : min;
            hour1 = hour1 > 12 ? hour1 - 12 : hour1;
            hour1 = (hour1 + '').length == 1 ? `0${hour1}` : hour1;
            this.attendanceSheetList[i].startTime = hour1 + ':' + min + part;

            let hour2 = (this.attendanceSheetList[i].EndTime.split(':'))[0]
            let min2 = (this.attendanceSheetList[i].EndTime.split(':'))[1]
            let part2 = hour2 > 12 ? 'pm' : 'am';
            min2 = (min2 + '').length == 1 ? `0${min2}` : min2;
            hour2 = hour2 > 12 ? hour2 - 12 : hour2;
            hour2 = (hour2 + '').length == 1 ? `0${hour2}` : hour2;
            this.attendanceSheetList[i].endTime = hour2 + ':' + min2 + part;
          }
          let todaysdate = this.globals.todaysdate;
          // setTimeout(function () {
          //   var table = $('#dataTables-example').DataTable({
          //     //  scrollY: '55vh',       
          //     scrollCollapse: true,
          //     "oLanguage": {
          //       "sLengthMenu": "_MENU_ Candidates per page",
          //       "sInfo": "Showing _START_ to _END_ of _TOTAL_ Candidates",
          //       "sInfoFiltered": "(filtered from _MAX_ total Candidates)",
          //       "sInfoEmpty": "Showing 0 to 0 of 0 Candidates"
          //     },
          //     "aoColumnDefs": [
          //       { 'bSortable': false, 'aTargets': [9, 10] }
          //     ],
          //     dom: 'lBfrtip',
          //     buttons: [
          //       {
          //         extend: 'excel',
          //         title: 'Assessment – All Candidates – ' + todaysdate,
          //         filename: 'Assessment–AllCandidates–' + todaysdate,

          //         customize: function (xlsx) {
          //           var source = xlsx.xl['workbook.xml'].getElementsByTagName('sheet')[0];
          //           source.setAttribute('name', 'Assessment-AllCandidates');
          //         },
          //         exportOptions: {
          //           columns: [0, 1, 2, 3, 4, 5, 6, 7, 8]
          //         }
          //       },
          //       {
          //         extend: 'print',
          //         title: 'Assessment – All Candidates – ' + todaysdate,
          //         exportOptions: {
          //           columns: [0, 1, 2, 3, 4, 5, 6, 7, 8]
          //         }
          //       },
          //     ]
          //   });

          //   $(".buttons-print").append("<i class='fa fa-print'></i>");
          //   $('.buttons-print').attr('title', 'Print');
          //   $('.buttons-print').attr('data-toggle', 'tooltip');
          //   $('.buttons-print').attr('data-placement', 'top');
          //   $(".buttons-excel").append("<i class='fa fa-file-excel-o'></i>");
          //   $('.buttons-excel').attr('title', 'Export to Excel');
          //   $('.buttons-excel').attr('data-toggle', 'tooltip');
          //   $('.buttons-excel').attr('data-placement', 'top');
          // }, 1000);
        },
          (error) => {
            this.globals.isLoading = false;
            if (error.text) {
              swal({
                //position: 'top-end',
                type: 'error',
                title: 'Oops...',
                text: "Something went wrong!"
              })
            }
          });

    } else {
      this.ProctorDashboardService.getFullAttendanceSheet(this.globals.authData.UserId)
        .then((data) => {
          debugger

          this.attendanceSheetList = data;
          this.gridData = data;
          for (let i = 0; i < this.attendanceSheetList.length; i++) {
            var newDate1 = new Date(this.attendanceSheetList[i].AssignDate);
            var hour = this.attendanceSheetList[i].EndTime.substring(0, 2);
            var newDate = new Date(newDate1.getFullYear(), newDate1.getMonth(), newDate1.getDate());
            newDate.setDate(newDate.getDate() + 1);
            newDate.setHours(this.attendanceSheetList[i].EndTime.substring(0, 2), this.attendanceSheetList[i].EndTime.substring(3, 5));
            this.attendanceSheetList[i].addedDate = newDate;

            let hour1 = (this.attendanceSheetList[i].StartTime.split(':'))[0]
            let min = (this.attendanceSheetList[i].StartTime.split(':'))[1]
            let part = hour1 > 12 ? 'pm' : 'am';
            min = (min + '').length == 1 ? `0${min}` : min;
            hour1 = hour1 > 12 ? hour1 - 12 : hour1;
            hour1 = (hour1 + '').length == 1 ? `0${hour1}` : hour1;
            this.attendanceSheetList[i].startTime = hour1 + ':' + min + part;

            let hour2 = (this.attendanceSheetList[i].EndTime.split(':'))[0]
            let min2 = (this.attendanceSheetList[i].EndTime.split(':'))[1]
            let part2 = hour2 > 12 ? 'pm' : 'am';
            min2 = (min2 + '').length == 1 ? `0${min2}` : min2;
            hour2 = hour2 > 12 ? hour2 - 12 : hour2;
            hour2 = (hour2 + '').length == 1 ? `0${hour2}` : hour2;
            this.attendanceSheetList[i].endTime = hour2 + ':' + min2 + part;
          }
          let todaysdate = this.globals.todaysdate;
          // setTimeout(function () {
          //   var table = $('#dataTables-example').DataTable({
          //     //  scrollY: '55vh',       
          //     scrollCollapse: true,
          //     "oLanguage": {
          //       "sLengthMenu": "_MENU_ Candidates per page",
          //       "sInfo": "Showing _START_ to _END_ of _TOTAL_ Candidates",
          //       "sInfoFiltered": "(filtered from _MAX_ total Candidates)",
          //       "sInfoEmpty": "Showing 0 to 0 of 0 Candidates"
          //     },
          //     "aoColumnDefs": [
          //       { 'bSortable': false, 'aTargets': [9, 10] }
          //     ],
          //     dom: 'lBfrtip',
          //     buttons: [
          //       {
          //         extend: 'excel',
          //         title: 'Assessment – All Candidates – ' + todaysdate,
          //         filename: 'Assessment–AllCandidates–' + todaysdate,

          //         customize: function (xlsx) {
          //           var source = xlsx.xl['workbook.xml'].getElementsByTagName('sheet')[0];
          //           source.setAttribute('name', 'Assessment-AllCandidates');
          //         },
          //         exportOptions: {
          //           columns: [0, 1, 2, 3, 4, 5, 6, 7, 8]
          //         }
          //       },
          //       {
          //         extend: 'print',
          //         title: 'Assessment – All Candidates – ' + todaysdate,
          //         exportOptions: {
          //           columns: [0, 1, 2, 3, 4, 5, 6, 7, 8]
          //         }
          //       },
          //     ]
          //   });

          //   $(".buttons-print").append("<i class='fa fa-print'></i>");
          //   $('.buttons-print').attr('title', 'Print');
          //   $('.buttons-print').attr('data-toggle', 'tooltip');
          //   $('.buttons-print').attr('data-placement', 'top');
          //   $(".buttons-excel").append("<i class='fa fa-file-excel-o'></i>");
          //   $('.buttons-excel').attr('title', 'Export to Excel');
          //   $('.buttons-excel').attr('data-toggle', 'tooltip');
          //   $('.buttons-excel').attr('data-placement', 'top');
          // }, 1000);
        },
          (error) => {
            this.globals.isLoading = false;
            if (error.text) {
              swal({
                //position: 'top-end',
                type: 'error',
                title: 'Oops...',
                text: "Something went wrong!"
              })
            }
          });
    }



  }

  //change candidate status present or absent
  ChangePresentStatus(attendance, i) {
    //if ($("#active" + i).prop("checked") == true) {
    if (i) {
      this.changePresentStatusEntity.IsActive = 1;
      attendance.DisplayText = 'Present';
    }
    else {
      this.changePresentStatusEntity.IsActive = 0;
      attendance.DisplayText = 'Absent';
    }
    this.globals.isLoading = true;
    this.changePresentStatusEntity.ProctorId = attendance.ProctorId;
    this.changePresentStatusEntity.ScheduleAssessmentId = attendance.ScheduleAssessmentId;
    this.changePresentStatusEntity.UserCertificateId = attendance.UserCertificateId;
    if (this.globals.authData.RoleId == 1) {
      this.DashboardService.ChangePresentStatus(this.changePresentStatusEntity)
        .then((data) => {
          this.globals.isLoading = false;

          if (this.changePresentStatusEntity.IsActive == 1) {
            swal({
              type: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.changePresentStatus.alerts.presentSuccess.type,
              title: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.changePresentStatus.alerts.presentSuccess.title,
              text: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.changePresentStatus.alerts.presentSuccess.text,
              showConfirmButton: false,
              timer: 4000
            })
          }
          else {
            swal({
              type: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.changePresentStatus.alerts.absentSuccess.type,
              title: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.changePresentStatus.alerts.absentSuccess.title,
              text: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.changePresentStatus.alerts.absentSuccess.text,
              showConfirmButton: false,
              timer: 4000
            })
          }
          window.location.href = '/attendanceSheet';
          this.changePresentStatusEntity = {};
        },
          (error) => {
            this.globals.isLoading = false;
            swal({
              //position: 'top-end',
              type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
              title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
              text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
              showConfirmButton: false,
              timer: 4000
            })
          });
    } else {
      this.ProctorDashboardService.ChangePresentStatus(this.changePresentStatusEntity)
        .then((data) => {
          this.globals.isLoading = false;

          if (this.changePresentStatusEntity.IsActive == 1) {
            swal({
              type: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.changePresentStatus.alerts.presentSuccess.type,
              title: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.changePresentStatus.alerts.presentSuccess.title,
              text: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.changePresentStatus.alerts.presentSuccess.text,
              showConfirmButton: false,
              timer: 4000
            })
          }
          else {
            swal({
              type: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.changePresentStatus.alerts.absentSuccess.type,
              title: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.changePresentStatus.alerts.absentSuccess.title,
              text: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.changePresentStatus.alerts.absentSuccess.text,
              showConfirmButton: false,
              timer: 4000
            })
          }
          window.location.href = '/attendanceSheet';
          this.changePresentStatusEntity = {};
        },
          (error) => {
            this.globals.isLoading = false;
            swal({
              //position: 'top-end',
              type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
              title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
              text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
              showConfirmButton: false,
              timer: 4000
            })
          });
    }

  }

  //feedback popup open
  finalFeedbackShow(attendance) {
    this.finalFeedbackEntity = {};
    this.submitted1 = false;
    this.finalFeedbackEntity.ProctorId = attendance.ProctorId;
    this.finalFeedbackEntity.UserId = attendance.UserId;
    this.finalFeedbackEntity.ScheduleAssessmentId = attendance.ScheduleAssessmentId;
    this.finalFeedbackEntity.CandidateName = attendance.CandidateName;
  }

  //final submit for feedback
  finalFeedbackSubmit(finalFeedbackForm) {
    this.submitted1 = true;
    if (finalFeedbackForm.valid) {
      this.globals.isLoading = true;
      if (this.globals.authData.RoleId == 1) {
        this.DashboardService.finalFeedback(this.finalFeedbackEntity)
          .then((data) => {
            this.globals.isLoading = false;
            this.submitted1 = false;
            $("#FeedbackModal").modal('hide');
            finalFeedbackForm.form.markAsPristine();
            this.finalFeedbackEntity = {};
            swal({
              type: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.finalFeedback.alerts.type,
              title: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.finalFeedback.alerts.title,
              text: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.finalFeedback.alerts.text,
              showConfirmButton: false,
              timer: 4000
            })
          },
            (error) => {
              this.globals.isLoading = false;
              swal({
                //position: 'top-end',
                type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
                title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
                text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
                showConfirmButton: false,
                timer: 4000
              })
            });
      } else {
        this.ProctorDashboardService.finalFeedback(this.finalFeedbackEntity)
          .then((data) => {
            this.globals.isLoading = false;
            this.submitted1 = false;
            $("#FeedbackModal").modal('hide');
            finalFeedbackForm.form.markAsPristine();
            this.finalFeedbackEntity = {};
            swal({
              type: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.finalFeedback.alerts.type,
              title: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.finalFeedback.alerts.title,
              text: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.finalFeedback.alerts.text,
              showConfirmButton: false,
              timer: 4000
            })
          },
            (error) => {
              this.globals.isLoading = false;
              swal({
                //position: 'top-end',
                type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
                title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
                text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
                showConfirmButton: false,
                timer: 4000
              })
            });
      }

    }
  }

  //resume assessment
  resumeAssessment(attendance) {
    swal({
      title: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.resumeAssessment.alerts.confirmResume.title,
      text: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.resumeAssessment.alerts.confirmResume.text,
      icon: "warning",
      type: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.resumeAssessment.alerts.confirmResume.type,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: "No",
    })
      .then((result) => {
        if (result.value) {
          this.globals.isLoading = true;
          this.resumeAssessmentEntity.ProctorId = attendance.ProctorId;
          this.resumeAssessmentEntity.ScheduleAssessmentId = attendance.ScheduleAssessmentId;
          if (this.globals.authData.RoleId == 1) {
            this.DashboardService.ResumeAssessment(this.resumeAssessmentEntity)
              .then((data) => {
                this.globals.isLoading = false;
                this.resumeAssessmentEntity = {};
                swal({
                  type: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.resumeAssessment.alerts.successResumeAssessment.type,
                  title: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.resumeAssessment.alerts.successResumeAssessment.title,
                  text: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.resumeAssessment.alerts.successResumeAssessment.text,
                  showConfirmButton: false,
                  timer: 4000
                })
                window.location.href = '/attendanceSheet';
              },
                (error) => {
                  this.globals.isLoading = false;
                  swal({
                    //position: 'top-end',
                    type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
                    title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
                    text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
                    showConfirmButton: false,
                    timer: 4000
                  })
                });
          } else {
            this.ProctorDashboardService.ResumeAssessment(this.resumeAssessmentEntity)
              .then((data) => {
                this.globals.isLoading = false;
                this.resumeAssessmentEntity = {};
                swal({
                  type: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.resumeAssessment.alerts.successResumeAssessment.type,
                  title: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.resumeAssessment.alerts.successResumeAssessment.title,
                  text: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.resumeAssessment.alerts.successResumeAssessment.text,
                  showConfirmButton: false,
                  timer: 4000
                })
                window.location.href = '/attendanceSheet';
              },
                (error) => {
                  this.globals.isLoading = false;
                  swal({
                    //position: 'top-end',
                    type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
                    title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
                    text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
                    showConfirmButton: false,
                    timer: 4000
                  })
                });
          }
        }

      })
  }

  //stop assessment popup open
  stopAssessmentShow(attendance) {
    this.stopAssessmentEntity = {};
    this.submitted2 = false;
    this.stopAssessmentEntity.StopAssessmentStatus = 43;
    this.stopAssessmentEntity.ProctorId = attendance.ProctorId;
    this.stopAssessmentEntity.UserId = attendance.UserId;
    this.stopAssessmentEntity.ScheduleAssessmentId = attendance.ScheduleAssessmentId;
    this.stopAssessmentEntity.CandidateName = attendance.CandidateName;
    this.stopAssessmentEntity.CertificateName = attendance.CertificateName;
  }

  //stop assessment submit
  stopAssessmentSubmit(stopAssessmentForm) {
    this.submitted2 = true;
    if (stopAssessmentForm.valid) {
      this.globals.isLoading = true;
      this.stopAssessmentEntity.LoginURL = '/login';
      if (this.globals.authData.RoleId == 1) {
        this.DashboardService.stopAssessment(this.stopAssessmentEntity)
          .then((data) => {
            this.globals.isLoading = false;
            $("#StopModal").modal('hide');
            stopAssessmentForm.form.markAsPristine();
            this.stopAssessmentEntity = {};
            swal({
              type: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.stopAssessment.alerts.type,
              title: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.stopAssessment.alerts.title,
              text: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.stopAssessment.alerts.text,
              showConfirmButton: false,
              timer: 4000
            })
            window.location.href = '/attendanceSheet';
          },
            (error) => {
              this.globals.isLoading = false;
              swal({
                //position: 'top-end',
                type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
                title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
                text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
                showConfirmButton: false,
                timer: 4000
              })
            });

      } else {
        this.ProctorDashboardService.stopAssessment(this.stopAssessmentEntity)
          .then((data) => {
            this.globals.isLoading = false;
            $("#StopModal").modal('hide');
            stopAssessmentForm.form.markAsPristine();
            this.stopAssessmentEntity = {};
            swal({
              type: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.stopAssessment.alerts.type,
              title: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.stopAssessment.alerts.title,
              text: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.stopAssessment.alerts.text,
              showConfirmButton: false,
              timer: 4000
            })
            window.location.href = '/attendanceSheet';
          },
            (error) => {
              this.globals.isLoading = false;
              swal({
                //position: 'top-end',
                type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
                title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
                text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
                showConfirmButton: false,
                timer: 4000
              })
            });
      }

    }
  }

  //document verification popup open
  documentVerificationShow(attendance) {
    debugger
    this.optionalDocumentsList = attendance['OptionalDocuments'];
    this.mandatoryDocumentsList = attendance['MandatoryDocuments'];
    this.commentDisplay = false;
    console.log(this.optionalDocumentsList);
    this.documentVerificationEntity = attendance;
    this.documentVerificationEntity.UserId = attendance.UserId;
    this.documentVerificationEntity.ProctorId = attendance.ProctorId;
    this.documentVerificationEntity.ScheduleAssessmentId = attendance.ScheduleAssessmentId;
    this.documentVerificationEntity.CandidateName = attendance.CandidateName;
    this.documentVerificationEntity.CertificateName = attendance.CertificateName;
  }

  //verifiy for mandatory document status change
  mandatoryDocumentsStatusChange(mandatoryDocuments, i) {
    debugger
    var IsVerify = 0;
    if (i)
      IsVerify = 1;
    this.globals.isLoading = true;

    if (this.globals.authData.RoleId == 1) {
      this.DashboardService.VerifyDocument(mandatoryDocuments.UserDocumentCertificateMappingId, IsVerify)
        .then((data) => {
          this.globals.isLoading = false;
          if (IsVerify == 1) {
            swal({
              type: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.documentsVerification.alerts.mandatoryDocumentVerify.type,
              title: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.documentsVerification.alerts.mandatoryDocumentVerify.title,
              text: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.documentsVerification.alerts.mandatoryDocumentVerify.text,
              showConfirmButton: false,
              timer: 5000
            })
          }
          else {
            swal({
              type: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.documentsVerification.alerts.mandatoryDocumentNotVerify.type,
              title: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.documentsVerification.alerts.mandatoryDocumentNotVerify.title,
              text: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.documentsVerification.alerts.mandatoryDocumentNotVerify.text,
              showConfirmButton: false,
              timer: 5000
            })
          }
        },
          (error) => {
            this.globals.isLoading = false;
            swal({
              type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
              title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
              text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
              showConfirmButton: false,
              timer: 4000
            })
          });
    } else {
      this.ProctorDashboardService.VerifyDocument(mandatoryDocuments.UserDocumentCertificateMappingId, IsVerify)
        .then((data) => {
          this.globals.isLoading = false;
          if (IsVerify == 1) {
            swal({
              type: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.documentsVerification.alerts.mandatoryDocumentVerify.type,
              title: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.documentsVerification.alerts.mandatoryDocumentVerify.title,
              text: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.documentsVerification.alerts.mandatoryDocumentVerify.text,
              showConfirmButton: false,
              timer: 5000
            })
          }
          else {
            swal({
              type: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.documentsVerification.alerts.mandatoryDocumentNotVerify.type,
              title: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.documentsVerification.alerts.mandatoryDocumentNotVerify.title,
              text: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.documentsVerification.alerts.mandatoryDocumentNotVerify.text,
              showConfirmButton: false,
              timer: 5000
            })
          }
        },
          (error) => {
            this.globals.isLoading = false;
            swal({
              type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
              title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
              text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
              showConfirmButton: false,
              timer: 4000
            })
          });
    }
  }

  //verifiy for optional document status change
  optionalDocumentsStatusChange(optionalDocuments, i) {
    var IsVerify = 0;
    //if ($("#docoptionalactive" + i).is(':checked'))
    if (i)
      IsVerify = 1;
    this.globals.isLoading = true;
    if (this.globals.authData.RoleId == 1) {
      this.DashboardService.VerifyDocument(optionalDocuments.UserDocumentCertificateMappingId, IsVerify)
        .then((data) => {
          this.globals.isLoading = false;
          if (IsVerify == 1) {
            swal({
              type: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.documentsVerification.alerts.optionalDocumentVerify.type,
              title: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.documentsVerification.alerts.optionalDocumentVerify.title,
              text: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.documentsVerification.alerts.optionalDocumentVerify.text,
              showConfirmButton: false,
              timer: 5000
            })
          }
          else {
            swal({
              type: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.documentsVerification.alerts.optionalDocumentNotVerify.type,
              title: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.documentsVerification.alerts.optionalDocumentNotVerify.title,
              text: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.documentsVerification.alerts.optionalDocumentNotVerify.text,
              showConfirmButton: false,
              timer: 5000
            })
          }
        },
          (error) => {
            this.globals.isLoading = false;
            swal({
              type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
              title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
              text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
              showConfirmButton: false,
              timer: 4000
            })
          });
    } else {
      this.ProctorDashboardService.VerifyDocument(optionalDocuments.UserDocumentCertificateMappingId, IsVerify)
        .then((data) => {
          this.globals.isLoading = false;
          if (IsVerify == 1) {
            swal({
              type: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.documentsVerification.alerts.optionalDocumentVerify.type,
              title: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.documentsVerification.alerts.optionalDocumentVerify.title,
              text: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.documentsVerification.alerts.optionalDocumentVerify.text,
              showConfirmButton: false,
              timer: 5000
            })
          }
          else {
            swal({
              type: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.documentsVerification.alerts.optionalDocumentNotVerify.type,
              title: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.documentsVerification.alerts.optionalDocumentNotVerify.title,
              text: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.documentsVerification.alerts.optionalDocumentNotVerify.text,
              showConfirmButton: false,
              timer: 5000
            })
          }
        },
          (error) => {
            this.globals.isLoading = false;
            swal({
              type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
              title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
              text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
              showConfirmButton: false,
              timer: 4000
            })
          });
    }
  }

  //document verification status updated
  DocumentVerificationStatus(i) {
    if (i == 0) {
      if (this.documentVerificationEntity.DocumentVerificationComment == null || this.documentVerificationEntity.DocumentVerificationComment == '' || this.documentVerificationEntity.DocumentVerificationComment == undefined) {
        this.commentDisplay = true;
      }
      else
        this.commentDisplay = false;
    }
    if (!this.commentDisplay) {
      if (i == 0) {
        this.documentVerificationEntity.DocumentVerificationStatus = 'Not Verify';
        var documentmsg = 'decline successfully';
        var documenttitle = 'Decline';
      }
      else {
        this.documentVerificationEntity.DocumentVerificationStatus = 'verify';
        var documentmsg = 'accept successfully';
        var documenttitle = 'Accept';
      }

      if (this.documentVerificationEntity.DocumentVerificationComment == null || this.documentVerificationEntity.DocumentVerificationComment == '' || this.documentVerificationEntity.DocumentVerificationComment == undefined) {
        this.documentVerificationEntity.DocumentVerificationComment = 'NA';
      }
      else
        this.documentVerificationEntity.DocumentVerificationComment = this.documentVerificationEntity.DocumentVerificationComment;


      this.documentVerificationEntity.UpdatedBy = this.globals.authData.UserId;
      //this.documentVerificationEntity.UserName = this.certificateDetail.Name;
      this.documentVerificationEntity.LoginURL = '/login';
      console.log(this.documentVerificationEntity);
      if (this.globals.authData.RoleId == 1) {
        if(i==1)
        {
          swal({
            title: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.documentsVerification.alerts.documentacceptproctormessage.title,
            text: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.documentsVerification.alerts.documentacceptproctormessage.text,
            icon: "warning",
            type: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.documentsVerification.alerts.documentacceptproctormessage.type,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: "No"
          })
          .then((result) => {
            if (result.value) {
              this.globals.isLoading = true;
              this.DashboardService.updateDocumentVerificationStatus(this.documentVerificationEntity)
              .then((data) => {
                this.globals.isLoading = false;
                $('#document_verify').modal('hide');
                swal({
                  type: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.documentsVerification.alerts.documentVerified.type,
                  title: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.documentsVerification.alerts.documentVerified.title + ' ' + documenttitle,
                  text: this.documentVerificationEntity.CandidateName + "'s " + this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.documentsVerification.alerts.documentVerified.text + ' ' + documentmsg,
                  showConfirmButton: false,
                  timer: 5000
                })
                window.location.href = '/attendanceSheet';
              },
              (error) => {
                this.globals.isLoading = false;
                swal({
                  type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
                  title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
                  text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
                  showConfirmButton: false,
                  timer: 4000
                })

              });
            }
          });
        }
        else
        {
          this.globals.isLoading = true;
          this.DashboardService.updateDocumentVerificationStatus(this.documentVerificationEntity)
            .then((data) => {
              this.globals.isLoading = false;
              $('#document_verify').modal('hide');
              swal({
                type: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.documentsVerification.alerts.documentVerified.type,
                title: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.documentsVerification.alerts.documentVerified.title + ' ' + documenttitle,
                text: this.documentVerificationEntity.CandidateName + "'s " + this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.documentsVerification.alerts.documentVerified.text + ' ' + documentmsg,
                showConfirmButton: false,
                timer: 5000
              })
              window.location.href = '/attendanceSheet';
            },
            (error) => {
              this.globals.isLoading = false;
              swal({
                type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
                title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
                text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
                showConfirmButton: false,
                timer: 4000
              })

            });
        }

      } else {
        if(i==1)
        {
          swal({
            title: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.documentsVerification.alerts.documentacceptproctormessage.title,
            text: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.documentsVerification.alerts.documentacceptproctormessage.text,
            icon: "warning",
            type: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.documentsVerification.alerts.documentacceptproctormessage.type,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: "No"
          })
          .then((result) => {
            if (result.value) {
              this.globals.isLoading = true;
              this.ProctorDashboardService.updateDocumentVerificationStatus(this.documentVerificationEntity)
              .then((data) => {
                this.globals.isLoading = false;
                $('#document_verify').modal('hide');
                swal({
                  type: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.documentsVerification.alerts.documentVerified.type,
                  title: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.documentsVerification.alerts.documentVerified.title + ' ' + documenttitle,
                  text: this.documentVerificationEntity.CandidateName + "'s " + this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.documentsVerification.alerts.documentVerified.text + ' ' + documentmsg,
                  showConfirmButton: false,
                  timer: 5000
                })
                window.location.href = '/attendanceSheet';
              },
                (error) => {
                  this.globals.isLoading = false;
                  swal({
                    type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
                    title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
                    text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
                    showConfirmButton: false,
                    timer: 4000
                  })

                });
            }
          });
        }
        else{
          this.globals.isLoading = true;
          this.ProctorDashboardService.updateDocumentVerificationStatus(this.documentVerificationEntity)
          .then((data) => {
            this.globals.isLoading = false;
            $('#document_verify').modal('hide');
            swal({
              type: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.documentsVerification.alerts.documentVerified.type,
              title: this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.documentsVerification.alerts.documentVerified.title + ' ' + documenttitle,
              text: this.documentVerificationEntity.CandidateName + "'s " + this.globals.commonTranslationText.proctorDashboard.attendanceSheet.list.documentsVerification.alerts.documentVerified.text + ' ' + documentmsg,
              showConfirmButton: false,
              timer: 5000
            })
            window.location.href = '/attendanceSheet';
          },
            (error) => {
              this.globals.isLoading = false;
              swal({
                type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
                title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
                text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
                showConfirmButton: false,
                timer: 4000
              })

            });
        }
      }


    }
  }

  SearchFilter(SearchFilterForm) {
    debugger
    if (this.filterEntity.AssignDate != '' && this.filterEntity.AssignDate != undefined) {
      var d = new Date(this.filterEntity.AssignDate);
      var ValidFromMonth = d.getMonth() + 1;
      var ValidFromDate = d.getDate();
      var ValidFromYear = d.getFullYear();
      this.filterEntity.AssignDate = ValidFromYear + '-' + (ValidFromMonth < 10 ? '0' + ValidFromMonth : '' + ValidFromMonth) + '-' + ((ValidFromDate < 10 ? '0' + ValidFromDate : '' + ValidFromDate));
    }

    //this.filterEntity.AssignDate = $("#AssignDate").val();
    if (this.globals.authData.RoleId != 1) {
      this.filterEntity.ProctorId = this.globals.authData.UserId;
    }
    this.filterEntity.RoleId = this.globals.authData.RoleId;
    console.log(this.filterEntity);
    this.globals.isLoading = true;
    this.attendanceSheetList = [];
    let todaysdate = this.globals.todaysdate;

    if (this.globals.authData.RoleId == 1) {
      this.DashboardService.filterAttendanceList(this.filterEntity)
        .then((data) => {
          this.globals.isLoading = false;
          this.attendanceSheetList = data;
          console.log(this.attendanceSheetList);
          //SearchFilterForm.form.markAsPristine();

          for (let i = 0; i < this.attendanceSheetList.length; i++) {
            var newDate1 = new Date(this.attendanceSheetList[i].AssignDate);
            var hour = this.attendanceSheetList[i].EndTime.substring(0, 2);
            var newDate = new Date(newDate1.getFullYear(), newDate1.getMonth(), newDate1.getDate());
            newDate.setDate(newDate.getDate() + 1);
            newDate.setHours(this.attendanceSheetList[i].EndTime.substring(0, 2), this.attendanceSheetList[i].EndTime.substring(3, 5));
            this.attendanceSheetList[i].addedDate = newDate;

            let hour1 = (this.attendanceSheetList[i].StartTime.split(':'))[0]
            let min = (this.attendanceSheetList[i].StartTime.split(':'))[1]
            let part = hour1 > 12 ? 'pm' : 'am';
            min = (min + '').length == 1 ? `0${min}` : min;
            hour1 = hour1 > 12 ? hour1 - 12 : hour1;
            hour1 = (hour1 + '').length == 1 ? `0${hour1}` : hour1;
            this.attendanceSheetList[i].startTime = hour1 + ':' + min + part;

            let hour2 = (this.attendanceSheetList[i].EndTime.split(':'))[0]
            let min2 = (this.attendanceSheetList[i].EndTime.split(':'))[1]
            let part2 = hour2 > 12 ? 'pm' : 'am';
            min2 = (min2 + '').length == 1 ? `0${min2}` : min2;
            hour2 = hour2 > 12 ? hour2 - 12 : hour2;
            hour2 = (hour2 + '').length == 1 ? `0${hour2}` : hour2;
            this.attendanceSheetList[i].endTime = hour2 + ':' + min2 + part;
          }
        },
          (error) => {
            this.globals.isLoading = false;
            this.attendanceSheetList = [];

          });
    } else {
      this.ProctorDashboardService.filterAttendanceList(this.filterEntity)
        .then((data) => {
          this.globals.isLoading = false;
          this.attendanceSheetList = data;
          console.log(this.attendanceSheetList);
          //SearchFilterForm.form.markAsPristine();
          for (let i = 0; i < this.attendanceSheetList.length; i++) {
            var newDate1 = new Date(this.attendanceSheetList[i].AssignDate);
            var hour = this.attendanceSheetList[i].EndTime.substring(0, 2);
            var newDate = new Date(newDate1.getFullYear(), newDate1.getMonth(), newDate1.getDate());
            newDate.setDate(newDate.getDate() + 1);
            newDate.setHours(this.attendanceSheetList[i].EndTime.substring(0, 2), this.attendanceSheetList[i].EndTime.substring(3, 5));
            this.attendanceSheetList[i].addedDate = newDate;

            let hour1 = (this.attendanceSheetList[i].StartTime.split(':'))[0]
            let min = (this.attendanceSheetList[i].StartTime.split(':'))[1]
            let part = hour1 > 12 ? 'pm' : 'am';
            min = (min + '').length == 1 ? `0${min}` : min;
            hour1 = hour1 > 12 ? hour1 - 12 : hour1;
            hour1 = (hour1 + '').length == 1 ? `0${hour1}` : hour1;
            this.attendanceSheetList[i].startTime = hour1 + ':' + min + part;

            let hour2 = (this.attendanceSheetList[i].EndTime.split(':'))[0]
            let min2 = (this.attendanceSheetList[i].EndTime.split(':'))[1]
            let part2 = hour2 > 12 ? 'pm' : 'am';
            min2 = (min2 + '').length == 1 ? `0${min2}` : min2;
            hour2 = hour2 > 12 ? hour2 - 12 : hour2;
            hour2 = (hour2 + '').length == 1 ? `0${hour2}` : hour2;
            this.attendanceSheetList[i].endTime = hour2 + ':' + min2 + part;
          }
        },
          (error) => {
            this.globals.isLoading = false;
            this.attendanceSheetList = [];

          });
    }


  }

  // if(this.globals.authData.RoleId == 1) {}else{}


  clearData(SearchFilterForm) {
    this.attendanceSheetList = [];
    this.globals.isLoading = true;
    if (this.globals.authData.RoleId == 1) {
      this.DashboardService.getFullAttendanceSheet()
        //.map(res => res.json())
        .then((data) => {
          this.filterEntity = {};
          this.attendanceSheetList = data;
          for (let i = 0; i < this.attendanceSheetList.length; i++) {
            var newDate1 = new Date(this.attendanceSheetList[i].AssignDate);
            var hour = this.attendanceSheetList[i].EndTime.substring(0, 2);
            var newDate = new Date(newDate1.getFullYear(), newDate1.getMonth(), newDate1.getDate());
            newDate.setDate(newDate.getDate() + 1);
            newDate.setHours(this.attendanceSheetList[i].EndTime.substring(0, 2), this.attendanceSheetList[i].EndTime.substring(3, 5));
            this.attendanceSheetList[i].addedDate = newDate;

            let hour1 = (this.attendanceSheetList[i].StartTime.split(':'))[0]
            let min = (this.attendanceSheetList[i].StartTime.split(':'))[1]
            let part = hour1 > 12 ? 'pm' : 'am';
            min = (min + '').length == 1 ? `0${min}` : min;
            hour1 = hour1 > 12 ? hour1 - 12 : hour1;
            hour1 = (hour1 + '').length == 1 ? `0${hour1}` : hour1;
            this.attendanceSheetList[i].startTime = hour1 + ':' + min + part;

            let hour2 = (this.attendanceSheetList[i].EndTime.split(':'))[0]
            let min2 = (this.attendanceSheetList[i].EndTime.split(':'))[1]
            let part2 = hour2 > 12 ? 'pm' : 'am';
            min2 = (min2 + '').length == 1 ? `0${min2}` : min2;
            hour2 = hour2 > 12 ? hour2 - 12 : hour2;
            hour2 = (hour2 + '').length == 1 ? `0${hour2}` : hour2;
            this.attendanceSheetList[i].endTime = hour2 + ':' + min2 + part;
          }
          // setTimeout(function () {
          //   $("#AssignDate").val('');
          //   $('select').selectpicker();
          //   $('select').selectpicker("refresh");
          // }, 200);
          this.globals.isLoading = false;
          // SearchFilterForm.form.markAsPristine();
        },
          (error) => {
            this.globals.isLoading = false;
            this.router.navigate(['/pagenotfound']);
          });

    } else {
      this.ProctorDashboardService.getFullAttendanceSheet(this.globals.authData.UserId)
        //.map(res => res.json())
        .then((data) => {
          this.filterEntity = {};
          this.attendanceSheetList = data;
          for (let i = 0; i < this.attendanceSheetList.length; i++) {
            var newDate1 = new Date(this.attendanceSheetList[i].AssignDate);
            var hour = this.attendanceSheetList[i].EndTime.substring(0, 2);
            var newDate = new Date(newDate1.getFullYear(), newDate1.getMonth(), newDate1.getDate());
            newDate.setDate(newDate.getDate() + 1);
            newDate.setHours(this.attendanceSheetList[i].EndTime.substring(0, 2), this.attendanceSheetList[i].EndTime.substring(3, 5));
            this.attendanceSheetList[i].addedDate = newDate;

            let hour1 = (this.attendanceSheetList[i].StartTime.split(':'))[0]
            let min = (this.attendanceSheetList[i].StartTime.split(':'))[1]
            let part = hour1 > 12 ? 'pm' : 'am';
            min = (min + '').length == 1 ? `0${min}` : min;
            hour1 = hour1 > 12 ? hour1 - 12 : hour1;
            hour1 = (hour1 + '').length == 1 ? `0${hour1}` : hour1;
            this.attendanceSheetList[i].startTime = hour1 + ':' + min + part;

            let hour2 = (this.attendanceSheetList[i].EndTime.split(':'))[0]
            let min2 = (this.attendanceSheetList[i].EndTime.split(':'))[1]
            let part2 = hour2 > 12 ? 'pm' : 'am';
            min2 = (min2 + '').length == 1 ? `0${min2}` : min2;
            hour2 = hour2 > 12 ? hour2 - 12 : hour2;
            hour2 = (hour2 + '').length == 1 ? `0${hour2}` : hour2;
            this.attendanceSheetList[i].endTime = hour2 + ':' + min2 + part;
          }
          // setTimeout(function () {
          //   $("#AssignDate").val('');
          //   $('select').selectpicker();
          //   $('select').selectpicker("refresh");
          // }, 200);
          this.globals.isLoading = false;
          // SearchFilterForm.form.markAsPristine();
        },
          (error) => {
            this.globals.isLoading = false;
            this.router.navigate(['/pagenotfound']);
          });
    }

  }
  showFilterLocation(value) {
    this.showFilterLocationEntity = value;
  }
  showFilterDate() {//alert($("#AssignDate").val())
    this.filterEntity.AssignDate = $("#AssignDate").val();
  }
  showFilterShift(value) {
    //if(value == '12:00:00')
    this.showFilterShiftEntity = value;
  }
  showFilterCandidate(value) {
    this.showFilterCandidateEntity = [];
    for (var i = 0; i < this.candidateList.length; i++) {
      for (var j = 0; j < value.length; j++) {
        if (value[j] == this.candidateList[i].UserId)
          this.showFilterCandidateEntity.push(this.candidateList[i].Name)
      }

    }
  }

  removelocation(filter) {
    console.log(this.filterEntity.Location);
    this.filterEntity.Location.splice($.inArray(filter, this.filterEntity.Location), 1);
    //$('#Location').selectpicker('deselectAll');
    // $('#Location').selectpicker('val', this.filterEntity.Location );
    // $('#Location').selectpicker('refresh');
    $('#Location').find('[value=' + this.filterEntity.Location.find(filter).html() + ']').prop('selected', false);
  }
  removeshift(filter) {
    console.log(this.filterEntity.shiftFilters);
    this.filterEntity.shiftFilters.splice($.inArray(filter, this.filterEntity.shiftFilters), 1);
  }
  removecandidate(filter) {
    console.log(this.filterEntity.UserId);
    this.filterEntity.UserId.splice($.inArray(filter, this.filterEntity.UserId), 1);
  }

  public onFilter(inputValue: string): void {
    this.attendanceSheetList = process(this.gridData, {
      filter: {
        logic: "or",
        filters: [
          {
            field: 'CandidateName',
            operator: 'contains',
            value: inputValue
          },
          {
            field: 'EmailAddress',
            operator: 'contains',
            value: inputValue
          },
          {
            field: 'PhoneNumber',
            operator: 'contains',
            value: inputValue
          },
          {
            field: 'AssignDate',
            operator: 'contains',
            value: inputValue
          },
          {
            field: 'StartTime',
            operator: 'contains',
            value: inputValue
          },
          {
            field: 'CertificateName',
            operator: 'contains',
            value: inputValue
          },
          {
            field: 'DisplayText',
            operator: 'contains',
            value: inputValue
          },
          {
            field: 'Value',
            operator: 'contains',
            value: inputValue
          }
        ],
      }
    }).data;

    this.dataBinding.skip = 0;
  }

}
