import { Component, OnInit } from '@angular/core';
import { Globals } from '.././globals';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { debug } from 'util';
import { JwtHelperService } from '@auth0/angular-jwt';
import { count } from 'rxjs/operators';
import { now } from '@amcharts/amcharts4/.internal/core/utils/Time';
import { getLocaleDateTimeFormat } from '@angular/common';
import { time } from '@amcharts/amcharts4/core';

declare var $, swal: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  loginEntity;
  submitted;
  btn_disable;
  invalid;
  current_time;
  time;

  constructor(private router: Router, private route: ActivatedRoute, private AuthService: AuthService, public globals: Globals) { }

  ngOnInit() {
    $(document).ready(function () {
      const body = document.querySelector('body');
      body.style.setProperty('--screen-height', $(window).height() + "px");
    });

    // $(function () {
    //   if (localStorage.chkbx && localStorage.chkbx != '') {
    //     $('#remember_me').attr('checked', 'checked');
    //     $('#Emailaddress').val(localStorage.usrname);
    //     $('#password').val(localStorage.pass);
    //   } else {
    //     $('#remember_me').removeAttr('checked');
    //     $('#Emailaddress').val('');
    //     $('#password').val('');
    //   }
    //   $('#remember_me').click(function () {
    //     if ($('#remember_me').is(':checked')) {
    //       // save username and password
    //       localStorage.usrname = $('#Emailaddress').val();
    //       localStorage.pass = $('#password').val();
    //       localStorage.chkbx = $('#remember_me').val();
    //     } else {
    //       localStorage.usrname = '';
    //       localStorage.pass = '';
    //       localStorage.chkbx = '';
    //     }
    //   });
    // });

    $("#password-show").click(function () {
      $(this).toggleClass("fa-eye fa-eye-slash")
      if ($("#password").attr("type") == "password") {
        $("#password").attr("type", "text");

      }
      else {
        $("#password").attr("type", "password");
      }
    });

    let id = this.route.snapshot.paramMap.get('id');
    if (id) {
      var id1 = new JwtHelperService().decodeToken(id);
      let CertificateId = [id1.CertificateId]
      var obj = { CertificateFor: id1.CertificateFor, CertificateId: CertificateId, UserId: id1.UserId }
      console.log(obj);
      this.AuthService.AddCertificate(obj)
        .then((data) => {
          this.router.navigate(['/login']);
        },
          (error) => {
            this.globals.isLoading = false;
            this.btn_disable = false;
            swal({
              type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
              title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
              text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
              showConfirmButton: false,
              timer: 4000
            })
          });
    }
    this.loginEntity = {};
  }

  filter(s) {
    var ms = s % 1000;
    s = (s - ms) / 1000;
    var secs = s % 60;
    s = (s - secs) / 60;
    var mins = s % 60;
    var hrs = (s - mins) / 60;
    //return hrs + ':' + mins + ':' + secs + ':' + ms;
    return hrs + ':' + mins + ':' + secs;
  }

  login(loginForm) {
    debugger
    this.submitted = true;
    if (loginForm.valid) {
      this.btn_disable = true;
      this.globals.isLoading = true;
      this.AuthService.checkLogin(this.loginEntity)
        .then((data) => {
          if (this.globals.authData.RoleId == 1)
            window.location.href = '/admin/dashboard';
          else if (this.globals.authData.RoleId == 2 || this.globals.authData.RoleId == 4)
            window.location.href = '/proctorDashboard';
          else
            window.location.href = '/dashboard';


          this.globals.isLoading = true;
        },
          (error) => {
            if (error.status == 404) {
              if (error.error.status == 'User Locked') {
                this.current_time = Date.now();
                const converted_last_time_stamp = new Date(error.error.time_lastattemp);
                if (this.current_time < converted_last_time_stamp.getTime()) {
                  const remaining_time = converted_last_time_stamp.getTime() - this.current_time;
                  const time = this.filter(remaining_time);
                  swal({
                    type: this.globals.commonTranslationText.loginPage.alerts.userLocked.type,
                    title: this.globals.commonTranslationText.loginPage.alerts.userLocked.title,
                    text: this.globals.commonTranslationText.loginPage.alerts.userLocked.text + time,
                    showConfirmButton: false,
                    timer: 4000
                  })
                }
              } else if (error.error.status == 'Please try again') {
                swal({
                  type: this.globals.commonTranslationText.loginPage.alerts.invalidCredential.type,
                  title: this.globals.commonTranslationText.loginPage.alerts.invalidCredential.title,
                  text: this.globals.commonTranslationText.loginPage.alerts.invalidCredential.text,
                  showConfirmButton: false,
                  timer: 4000
                })
              }
              else if (error.error.status == 'Invalid Email') {
                swal({
                  type: this.globals.commonTranslationText.loginPage.alerts.invalidEmail.type,
                  title: this.globals.commonTranslationText.loginPage.alerts.invalidEmail.title,
                  text: this.globals.commonTranslationText.loginPage.alerts.invalidEmail.text,
                  showConfirmButton: false,
                  timer: 4000
                })
              }
              else if (error.error.status == 'User banded') {
                swal({
                  type: this.globals.commonTranslationText.loginPage.alerts.userBaned.type,
                  title: this.globals.commonTranslationText.loginPage.alerts.userBaned.title,
                  text: this.globals.commonTranslationText.loginPage.alerts.userBaned.text,
                  showConfirmButton: false,
                  timer: 4000
                })
              }
            } else {
              swal({
                type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
                title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
                text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
                showConfirmButton: false,
                timer: 4000
              })
            }
            this.globals.isLoading = false;
            this.btn_disable = false;
            this.submitted = false;
          });
    }
  }

}

